import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslocoService } from "@jsverse/transloco";

@Component({
	selector: 'box-drop-files',
	templateUrl: './box-drop-files.component.html',
	styleUrls: ['./box-drop-files.component.scss']
})
export class AwsFileUploadBoxDropFilesComponent implements OnInit {

	@ViewChild('boxdrop') boxDropFiles: ElementRef<HTMLDivElement>;

	@Input() maxAllowed = 1;
	@Input() disabled = false;
	@Input() accept = '' // accept="image" = "image/*" from Input File Tag
	@Input() translatePlaceHolder = 'FileUploadBoxDrop.PlaceHolder'
	@Input() translateMaxAllowedOne = 'FileUploadBoxDrop.MaxAllowedOne'
	@Input() translateMaxAllowedMoreThanTwo = 'FileUploadBoxDrop.MaxAllowedMoreThanTwo'

	@Output() onFilesDrop = new EventEmitter<File[]>();

	constructor(
		private matSnackBar: MatSnackBar,
		private translocoService: TranslocoService,
	){

	}

	ngOnInit(): void {

	}

	dragFileStart($event: DragEvent){
		if(this.disabled){
			return;
		}
		this.boxDropFiles.nativeElement.classList.add('is-over');
	}

	dragFileEnd($event: DragEvent){

		if(this.disabled){
			return;
		}

		this.boxDropFiles.nativeElement.classList.remove('is-over');

		if($event.type !== 'drop'){
			return;
		}

		const files: File[] = [];

		for(let i=0; i<$event.dataTransfer?.files.length; i++){
			const regexp = new RegExp(`^${this.accept}`);
			if(!this.accept || regexp.test($event.dataTransfer.files[i].type)){
				files.push($event.dataTransfer.files[i]);
			}
		}

		if(files.length <= this.maxAllowed){
			this.onFilesDrop.emit(files);
		}else{
			this.matSnackBar.open(
				this.translocoService.translate(
					this.maxAllowed === 1
						? this.translateMaxAllowedOne
						: this.translateMaxAllowedMoreThanTwo,
					{
						value: this.maxAllowed
					}
				),
				null,
				{
					panelClass: 'warn',
					duration: 3000
				}
			);
		}

	}

}
