<div
	layout="column"
	layout-fill
>
	<mat-toolbar flex="none">
		<span *ngIf="mode === 'add'">
			{{'Components.MasterData.NewClient.Header.Label' | transloco}}
		</span>

		<span *ngIf="mode === 'edit'">
			{{'Components.MasterData.EditClient.Header.Label' | transloco}}
		</span>
	</mat-toolbar>

	<div
		flex
		overflow-auto-y
		layout="row"
		layout-padding
	>
		<form
			*ngIf="!loading && !error"
			[formGroup]="formGroup"
			(ngSubmit)="submit()"
			flex
			layout-padding
			layout="column"
			layout-fill
		>
			<div layout="column">
				<div
					class="form-client-infos"
					layout="row"
					layout-wrap
					layout-align="space-between center"
				>
					<mat-form-field flex-gt-xs="25" flex-xs="55">
						<mat-label>{{'Components.MasterData.ClientId.Label' | transloco}}</mat-label>
						<input type="text" matInput autocomplete="off" formControlName="clientId" maxlength="50">
					</mat-form-field>

					<mat-form-field
						flex
						flex-xs="100"
						flex-order-xs="3"
					>
						<mat-label>{{'Components.MasterData.ClientName.Label' | transloco}}</mat-label>
						<input type="text" matInput autocomplete="off" formControlName="clientName" maxlength="50">
					</mat-form-field>

					<div
						class="mat-form-field-wrapper"
						layout="column"
						layout-margin
						no-margin-top
						flex-xs
						flex-order-xs="2"
					>
						<mat-slide-toggle
							layout="column"
							color="primary"
							labelPosition="before"
							class="active-switcher"
							[disabled]="loadingFormSubmit"
							[checked]="formGroup.get('active').value === 0"
							(change)="this.setDisabledValue(
								$event,
								formGroup.get('active'),
								[0, 1]
							)"
						>
							<div
								layout="row"
								layout-align="start center"
								flex
							>
								<span>{{'Components.Labels.SwitcherDisabled' | transloco}}</span>
							</div>
						</mat-slide-toggle>
					</div>
				</div>

				<mat-form-field>
					<mat-label>{{'Components.MasterData.ClientEmail.Label' | transloco}}</mat-label>
					<input type="text" matInput autocomplete="off" formControlName="clientEmail" maxlength="50">
				</mat-form-field>

				<mat-form-field>
					<mat-label>{{'Components.MasterData.ClientPhone.Label' | transloco}}</mat-label>
					<input type="text" matInput autocomplete="off" formControlName="clientPhone" maxlength="50">
				</mat-form-field>

					<div
						layout="column"
						layout-margin
						no-margin-left
						class="form-address"
					>
						<div
							layout="column"
							class="mat-form-field-wrapper"
						>
							<mat-slide-toggle
								layout="column"
								color="primary"
								labelPosition="after"
								class="active-switcher"
								[disabled]="loadingFormSubmit"
								[(ngModel)]="hasAddress"
								[ngModelOptions]="{standalone: true}"
								(change)="hasAddressChanged($event)"
							>
								<div
									layout="row"
									layout-align="start center"
									flex
								>
									<span>{{'Route.ServiceOrderPlanning.Routes.AdminSites.SetAddressLabel' | transloco}}</span>
								</div>
							</mat-slide-toggle>
						</div>

						<form-control-address
							*ngIf="hasAddress"
							[formControls]="formFieldAddressInputFormsControl"
						></form-control-address>
					</div>

			</div>

			<div
				layout="row"
				layout-align="space-between"
			>
				<button
					(click)="onCancelClientSidenav()"
					type="button"
					mat-button
					[disabled]="loadingFormSubmit"
					>
					{{'Components.Labels.ButtonCancel' | transloco}}
				</button>

				<button
					type="submit"
					layout-align="center"
					mat-raised-button
					color="primary"
					[disabled]="loadingFormSubmit"
				>
					<span *ngIf="mode === 'add'">{{'Components.Labels.ButtonAdd' | transloco}}</span>
					<span *ngIf="mode === 'edit'">{{'Components.Labels.ButtonUpdate' | transloco}}</span>

					<loading-spinner
						*ngIf="loadingFormSubmit"
						size="36"
						overlay-fill="no-translucid"
						[delegate]="loadingSpinnerDelegate"
					></loading-spinner>
				</button>
			</div>
		</form>

		<div
			*ngIf="loading && !error"
			flex
			layout="row"
			layout-align="center center"
			layout-padding
			layout-margin
		>
			<loading-spinner
				size="100"
			></loading-spinner>
		</div>

		<div
			*ngIf="!loading && error"
			layout="column"
			layout-padding
			layout-margin
			flex
		>
			<p
				class="mat-h2"
				no-margin-bottom
			>
				{{error.friendlyMessage.message}}
			</p>
		</div>
	</div>
</div>
