import { Injectable, Type, ViewContainerRef } from '@angular/core';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';

@Injectable({
	providedIn: 'root'
})
export class MainControllerService {

	private _backButton: boolean | string = false;

	navigationBar = false;
	sideNav: MatSidenav;
	sideNavBehavior = {
		minimized: false
	};

	matSidenavContent: MatSidenavContent;

	private rightIconsComponentAttached: Type<any>;
	rightIconsRef: ViewContainerRef;

	enableNavigationBar(){
		this.navigationBar = true;
	}

	disableNavigationBar(){
		this.navigationBar = false;
	}

	setSidenav(matSidenav: MatSidenav){
		this.sideNav = matSidenav;
	}

	getSidenav = (): MatSidenav => this.sideNav;

	closeSideNav(){
		if(this.sideNav){
			this.sideNav.close();
		}
	}

	openSideNav(){
		if(this.sideNav){
			this.sideNav.open();
		}
	}

	showBackButton(path: boolean | string = true){
		this._backButton = path;
	}

	hideBackButton(){
		this._backButton = false;
	}

	get backButton(){
		return this._backButton;
	}

	addRightIcons(component: Type<any>){
		this.rightIconsComponentAttached = component;
		if(this.rightIconsRef){
			setTimeout(() => {
				this.rightIconsRef.clear();
				this.rightIconsRef.createComponent(component);
			});
		}
	}

	refreshRightIcons(){
		if(this.rightIconsComponentAttached){
			this.addRightIcons(this.rightIconsComponentAttached);
		}
	}

	clearRightIcons(){
		if(this.rightIconsRef){
			this.rightIconsRef.clear();
		}
		this.rightIconsComponentAttached = null;
	}

}
