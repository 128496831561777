import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'photo-dialog',
	templateUrl: './photo-dialog.component.html',
	styleUrls: ['./photo-dialog.component.scss']
})
export class PhotoDialogComponent {
	  constructor(@Inject(MAT_DIALOG_DATA) public data: PhotoDialogComponentData) {}
}

export interface PhotoDialogComponentData {
	url: string;
};
