<mat-form-field
	layout="column"
	flex="none"
	wrapper-no-padding-bottom
	no-margin-bottom
>
	<mat-label>{{'Route.Dashboard.Components.FilterLevel.Label' | transloco}}</mat-label>
	<mat-select
		[(ngModel)]="level"
		(selectionChange)="levelChange()"
	>
		<mat-option [value]="eOptionValues.management">
			{{'Route.Dashboard.Components.FilterLevel.OptionManagement' | transloco}}
		</mat-option>
		<mat-option [value]="eOptionValues.operational">
			{{'Route.Dashboard.Components.FilterLevel.OptionOperational' | transloco}}
		</mat-option>
	</mat-select>
</mat-form-field>
