import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class AppService {

	device: string;
	private whiteLabelProperties: WhiteLabelProperties;

	constructor(
	){
	}

	setWhiteLabel(){
		this.whiteLabelProperties = structuredClone(window.getCurrentWhieLabelProperties());
	}

	getWhiteLabelProperties(): WhiteLabelProperties {
		return this.whiteLabelProperties;
	}

}
