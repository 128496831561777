import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslocoService } from '@jsverse/transloco';
import { Subject, Subscription, tap } from 'rxjs';
import { APIuploadAwsFileWebappService, FileUploadData } from 'src/app/api-services/portal/upload-aws-file-webapp.service';
import { ShortNumberSuffixPipe } from 'src/app/pipes/short-number-suffix.pipe';
import { compressImage } from './compress-img-file';

@Component({
	selector: 'aws-file-upload',
	templateUrl: './aws-file-upload.component.html',
	styleUrls: ['./aws-file-upload.component.scss']
})
export class AwsFileUploadComponent implements OnInit{

	private inputHTML: HTMLInputElement;

	required = false;
	fileUploadData: FileUploadDataPhoto;
	fielUploadSubscribe: Subscription;

	@Input() photoPreview = false;
	@Input({required: true}) awsFileUploadFormControl: UntypedFormControl;
	@Input() fileNamePrefix: string;
	@Input() useOriginalFileName: boolean = false;
	@Input() setFormControlValidator: boolean;
	@Input() accept = 'image/*';
	@Input() capture = false;
	@Input() icon = 'camera';
	@Input() maxMB: number;

	@Input() initialFileName: string;
	@Input() labelAddLabel: string;
	@Input() labelChangeLabel: string;
	@Input() labelDeleteLabel: string;

	@Input() buttonClassName: string;

	@Output() photoUploadResult = new EventEmitter<FileUploadDataPhoto>();

	constructor(
		private datePipe: DatePipe,
		private translocoService: TranslocoService,
		private domSanitizer: DomSanitizer,
		private apiUploadAwsFileWebappService: APIuploadAwsFileWebappService,
		private matSnackBar: MatSnackBar,
		private shortNumberSuffixPipe: ShortNumberSuffixPipe,
	) {
	}

	ngOnInit() {
		this.initFileUploadData();
		this.addFormControlValidator();
	}

	initFileUploadData(){
		if(this.awsFileUploadFormControl.value){
			this.fileUploadData = {
				done: true,
				fail: false,
				inProgress: false,
				file: null,
				generatedImage: null,
				generatedImageSanatizedUrl: this.sanatizeUrl(this.awsFileUploadFormControl.value),
				progress: 0
			};
		};
	}

	private addFormControlValidator(){
		if(this.setFormControlValidator && this.awsFileUploadFormControl){
			this.awsFileUploadFormControl.addValidators(AwsFileUploadFormControlValidator(this));
			this.awsFileUploadFormControl.addValidators(AwsFileUploadFormControlValidatorMaxSize(this));
			this.required = this.awsFileUploadFormControl.validator({} as AbstractControl)?.required;
		}
	}

	handleFileInput(event: Event){

		this.inputHTML = event.target as HTMLInputElement;

		if(this.inputHTML.files?.length === 0){
			return;
		}

		this.handleFile(this.inputHTML.files[0] as File);

	}

	async handleFile(file: File){

		if(!file){
			return;
		}

		const extension = file.name.replace(/^.*(\..+)$/g, '$1');

		const fileName = this.fileNamePrefix
			+ (
				this.useOriginalFileName
					? file.name
					: (
						this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH.mm.ss.SSS')
						+ extension
					)
			)

		if(/image\//.test(file.type)){
			compressImage(file, 1, .4).then(
				compressedFile => this.subscribeUpload(this.uploadFile(compressedFile, fileName))
			);
		}else{
			this.subscribeUpload(this.uploadFile(file, fileName));
		}


	}

	private uploadFile(file: File, name: string = null): Subject<FileUploadDataPhoto> {

		const fileUploadData = this.getFileUploadData(file, name);

		const subscriber = new Subject<FileUploadDataPhoto>();

		// initial notification
		subscriber.next(fileUploadData);

		// max size error
		if(this.awsFileUploadFormControl && this.maxMB){
			this.setFileUploadData(fileUploadData);
			this.awsFileUploadFormControl.updateValueAndValidity();

			if(this.awsFileUploadFormControl?.errors?.maxsize){
				fileUploadData.done = true;
				fileUploadData.inProgress = false;
				this.snackbarMaxSizeError();
				return subscriber;
			}
		}

		this.apiUploadAwsFileWebappService.upload(
			fileUploadData,
			{
				extras: {
					friendlyMessage: {
						payloadError: {
							message: this.translocoService.translate('Components.NForm.PHOTO.upload-payload-error')
						},
						serverError: {
							message: this.translocoService.translate('Components.NForm.PHOTO.upload-server-error')
						}
						// serverError: false
					}
				}
			}
		)
			.pipe(
				tap(() => {
					subscriber.next(fileUploadData);
					// [todo] move set form control value to subscribe below
					this.setFormControlValue(fileUploadData.file.name);
				})
			)
			.subscribe(
				(event: any) => {
					if (typeof (event) === 'object') {
						// [todo] remove set form control value to subscribe above
						// this.setFormControlValue(fileUploadData.file.name);
						this.photoUploadResult.emit(this.fileUploadData);
						subscriber.complete();
					}
				},
				error => {
					subscriber.error(error);
				}
			);

		return subscriber;

	};

	private setFormControlValue(tmpUrl: string){
		if(this.awsFileUploadFormControl){
			this.awsFileUploadFormControl.setValue(tmpUrl);
		}
	}

	private getFileUploadData(file: File, name: string = null): FileUploadDataPhoto {

		const newBlob = file.slice(0, file.size, file.type);
		const newFile = new File(
			[newBlob],
			name,
			{type: file.type}
		);


		const fileUploadData: FileUploadDataPhoto = {
			done: false,
			fail: false,
			inProgress: true,
			file: newFile,
			generatedImage: null,
			generatedImageSanatizedUrl: null,
			progress: 0
		};

		if(this.photoPreview){
			const generatedImage = window.URL.createObjectURL(newFile);
			fileUploadData.generatedImage = generatedImage;
			fileUploadData.generatedImageSanatizedUrl = this.sanatizeUrl(generatedImage);
		}

		return fileUploadData;

	}

	private sanatizeUrl(generatedImageUrl): SafeResourceUrl {
		return this.domSanitizer.bypassSecurityTrustResourceUrl(generatedImageUrl);
	}

	private setFileUploadData(fileUploadData: FileUploadDataPhoto){
		this.fileUploadData = fileUploadData;
		this.photoUploadResult.emit(this.fileUploadData);
	}

	private subscribeUpload(upload: Subject<FileUploadDataPhoto>){

		if(this.fielUploadSubscribe){
			this.fielUploadSubscribe.unsubscribe();
		}

		this.fielUploadSubscribe = upload.subscribe(

			fileUploadData => {

				this.setFileUploadData(fileUploadData);

				// if(this.formControl.value !== this.fileUploadData.file.name){
				// 	this.formControl.setValue(this.fileUploadData.file.name);
				// }

				// if(fileUploadData.done || fileUploadData.fail){
				// 	this.formControl.updateValueAndValidity();
				// }

				// this.formControl.markAllAsTouched();
				// this.cdr.detectChanges();

			},

			error => {
				// this.formControl.setErrors({uploadFailed: true});
				// this.formControl.updateValueAndValidity();
				// this.formControl.markAllAsTouched();
				// this.cdr.detectChanges();
				console.log('photoComponent upload fail');
			}

		);

	}

	private snackbarMaxSizeError(){
		this.matSnackBar.open(
			// this.translocoService.translate('Components.TicketOpenNotAllowedOnTemp'),
			this.translocoService.translate('Components.AWSFileUpload.MaxFileSizeErrorSnackLabel', {
				value: `${this.shortNumberSuffixPipe.transform(this.maxMB * Math.pow(1000,2))}b`
			}),
			null,
			{
				panelClass: 'warn',
				duration: 3000
			}
		);
	}

	getClassName(){
		if(this.buttonClassName){
			return this.buttonClassName;
		}else{
			return {
				'mat-mdc-raised-button mat-mdc-button-base': !this.fileUploadData,
				'mdc-button--outlined mat-mdc-outlined-button mat-mdc-button-base': !!this.fileUploadData
			};
		}
	}

	delete(){
		this.setFileUploadData(null);
		this.setFormControlValue(null);
		if(this.inputHTML){
			this.inputHTML.value = '';
		}
	}

}

export interface FileUploadDataPhoto extends FileUploadData {
	generatedImage: string;
	generatedImageSanatizedUrl: SafeResourceUrl;
}

export function AwsFileUploadFormControlValidator(awsFileUploadComponent: AwsFileUploadComponent) {

	return (control: AbstractControl): ValidationErrors | null => {

		if(!awsFileUploadComponent){
			return null;
		}

		if(
			awsFileUploadComponent.fileUploadData?.inProgress
			||
			awsFileUploadComponent.fileUploadData?.fail
		){
			return {
				inProgress: awsFileUploadComponent.fileUploadData?.inProgress,
				fail: awsFileUploadComponent.fileUploadData?.fail
			};
		}

		return null;

	}

}

export function AwsFileUploadFormControlValidatorMaxSize(awsFileUploadComponent: AwsFileUploadComponent) {

	return (control: AbstractControl): ValidationErrors | null => {

		if(
			!awsFileUploadComponent || !awsFileUploadComponent.maxMB
			|| !awsFileUploadComponent.fileUploadData?.file
		){
			return null;
		}

		if(
			awsFileUploadComponent.fileUploadData?.file.size / 1024
			>
			awsFileUploadComponent.maxMB * 1024 - 1
		){
			return {
				maxsize: true,
			};
		}

		return null;

	}

}
