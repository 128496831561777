import packageInfo from '../../package.json';

export const environment = {
	version: packageInfo.version,
	proxyGet_enc_url: '/portal_get_enc_url/',
	fusionChartsKey: 'ThB4d1E-13sA1C4A1H3C9B7C5B5E3D2H5A2D3pckC5E3lji1I-'
		+ '7kC8A3bvgF2A2H2C10D7D5A4B2B3D3E2B2B6C8B4E6vikI4F2C8kB5D6D3C-8B-'
		+ '8tG2C9A4rveE2F2D1vnjD1B5D1A2A2A15A20B7B9B6C6megD3J2VA7isbE3G1H2sbA4ED3D2B-'
		+ '9xiF1C10B8C4A3C3F3H4I2J2C10D3B1B5q==',
	googleMapsKey: 'AIzaSyA9WZi56mRAKKfgeFr7vT_4V6XQd8pwTiA',
	envMainBootstrap: (appBootstrap: () => void): boolean => false
};
