import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthPermissionsService } from 'src/app/auth/auth-permissions.service';
import { UserPermissions } from 'src/app/auth/auth.interface';

@Directive({
	selector: '[userPermissions]'
})
export class UserPermissionsDirective {

	constructor(
		private element: ElementRef,
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
		private authPermissionsService: AuthPermissionsService,
	) { }

	@Input()
	set userPermissions(key: UserPermissions) {
		if(this.authPermissionsService.has(key)) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}

}
