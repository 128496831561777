<mat-form-field
	layout="column"
	flex="none"
	wrapper-no-padding-bottom
	wrapper-no-margin
>
	<mat-label>
		{{'Route.MetricsChart.Components.SelectTimeUnit.Label' | transloco}}
	</mat-label>
	<mat-select
		[(ngModel)]="model.timeUnit"
		(selectionChange)="setTimeUnit()"
	>
		<mat-option [value]="eMetricsChartTimeUnit.month">
			{{'Route.MetricsChart.Chart.timeUnit.month' | transloco | titlecase}}
		</mat-option>
		<mat-option  [value]="eMetricsChartTimeUnit.day">
			{{'Route.MetricsChart.Chart.timeUnit.day' | transloco | titlecase}}
		</mat-option>
	</mat-select>
</mat-form-field>
