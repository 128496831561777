<input
	id="aws-file-{{fileNamePrefix}}-upload-input"
	type="file"
	[accept]="accept"
	[attr.capture]="capture ? '' : null"
	(change)="handleFileInput($event)"
	class="inputfile"
>

<div
	*ngIf="!awsFileUploadFormControl.disabled"
	layout-xs="column"
	layout-gt-xs="row"
	layout-margin
	layout-align="space-around"
	no-margin
>
	<button
		*ngIf="!fileUploadData || fileUploadData.done || fileUploadData.progress"
		type="button"
		flex
		layout="column"
		mat-button
		color="primary"
		class="btn-upload"
		[ngClass]="getClassName()"
		[disabled]="awsFileUploadFormControl.disabled || (fileUploadData && fileUploadData.inProgress)"
	>
		<label
			class="label-button-click"
			for="aws-file-{{fileNamePrefix}}-upload-input"
		>
			<div
				*ngIf="!fileUploadData || (fileUploadData && !fileUploadData.inProgress)"
				layout="row"
				layout-align="center center"
			>
				<mat-icon [svgIcon]="icon"></mat-icon>
				<span *ngIf="!fileUploadData" text-nowrap>
					{{labelAddLabel || ('Components.AWSFileUpload.AddPhotoLabel' | transloco)}}
				</span>
				<span *ngIf="fileUploadData" text-nowrap>
					{{labelChangeLabel || ('Components.AWSFileUpload.ChangePhotoLabel' | transloco)}}
				</span>
				<span *ngIf="required">*</span>
			</div>
			<div
				*ngIf="fileUploadData && fileUploadData.inProgress"
				layout="row"
				layout-align="center center"
			>
				<span no-padding>{{fileUploadData.progress}}</span>
			</div>
		</label>
		<loading-spinner
			*ngIf="fileUploadData && fileUploadData.inProgress"
			size="36"
			overlay-fill="no-translucid"
		></loading-spinner>
	</button>

	<button
		*ngIf="fileUploadData && !fileUploadData.inProgress"
		type="button"
		flex
		layout="column"
		mat-stroked-button
		color="warn"
		class="btn-delete"
		(click)="delete()"
	>
		<div
			layout="row"
			layout-align="center center"
		>
			<mat-icon flex="none" svgIcon="delete_forever"></mat-icon>
			<span text-nowrap>{{labelDeleteLabel || ('Components.AWSFileUpload.DeletePhotoLabel' | transloco)}}</span>
		</div>
	</button>

</div>

<div
	layout="row"
	layout-align="center center"
	class="preview-wrapper"
>
	<p
		*ngIf="!photoPreview"
		class="file-name-no-preview"
	>
		<ng-container *ngIf="fileUploadData?.file">
			{{fileUploadData?.file?.name}}
			<span *ngIf="fileUploadData?.file?.size">
				<!-- {{fileUploadData?.file?.size / 1024 | number: '1.0-0'}} Kb -->
				{{fileUploadData?.file?.size | shortNumberSuffix}}b
			</span>
		</ng-container>
		<ng-container *ngIf="fileUploadData?.generatedImageSanatizedUrl">
			<a
				*ngIf="fileUploadData?.generatedImageSanatizedUrl"
				[href]="fileUploadData.generatedImageSanatizedUrl"
				class="link"
				target="_blank"
			>
				{{initialFileName}}
			</a>
		</ng-container>
	</p>
	<a
		*ngIf="photoPreview && fileUploadData?.generatedImageSanatizedUrl"
		[href]="fileUploadData.generatedImageSanatizedUrl"
		target="_blank"
	>
		<img
			[src]="fileUploadData.generatedImageSanatizedUrl"
			class="photo"
		>
	</a>
</div>

<div
	*ngIf="awsFileUploadFormControl?.errors"
	class="form-control-errors"
>
	<span *ngIf="awsFileUploadFormControl?.errors?.required">
		{{"Form.ValidationMessage.required" | transloco}}
	</span>
	<span *ngIf="awsFileUploadFormControl?.errors?.maxsize">
		{{'Components.AWSFileUpload.MaxFileSizeErrorSnackLabel' | transloco: {value: (this.maxMB * 1000 * 1000 | shortNumberSuffix) + 'b' } }}
	</span>
</div>
