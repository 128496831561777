import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { autoUnsubscribe, AutoUnsubscribeOnDestroy, getObservableAndAutoUnsubscribe } from 'src/app/helpers/auto-unsubscribe';
import { MetricsChartFilterObservableResult, MetricsChartModel, MetricsChartService, MetricsChartTimeUnit } from '../../metrics-chart.service';

@Component({
	selector: 'select-time-unit',
	templateUrl: './select-time-unit.component.html',
	styleUrls: ['./select-time-unit.component.scss']
})
export class SelectTimeUnitComponent implements OnInit, OnDestroy, AutoUnsubscribeOnDestroy {

	eMetricsChartTimeUnit = MetricsChartTimeUnit;
	obs: Subscription[] = [];

	model: MetricsChartModel = {
		timeUnit: MetricsChartTimeUnit.month
	};

	constructor(
		private metricsChartService: MetricsChartService
	) {}

	ngOnInit(): void {

		// time unit change
		getObservableAndAutoUnsubscribe(
			this,
			this.metricsChartService.getFilterObservable()
				.subscribe((result: MetricsChartFilterObservableResult) => {
					setTimeout(() =>
						Object.assign(
							this.model,
							result.model
						)
					, 0);
				})
		);

	}

	setTimeUnit(){
		this.metricsChartService.setTimeUnit(this.model.timeUnit);
	}

	ngOnDestroy(): void {
		autoUnsubscribe(this);
	}

}
