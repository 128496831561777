import { Directive } from '@angular/core';
import * as i0 from "@angular/core";
var FusionChartsDirective = /** @class */function () {
  function FusionChartsDirective(el) {
    this.el = el;
  }
  FusionChartsDirective.ɵfac = function FusionChartsDirective_Factory(t) {
    return new (t || FusionChartsDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
  };
  FusionChartsDirective.ɵdir = /*@__PURE__*/i0.ɵɵdefineDirective({
    type: FusionChartsDirective,
    selectors: [["", "appFusionchartsDirective", ""]]
  });
  return FusionChartsDirective;
}();
export { FusionChartsDirective };
(function () {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FusionChartsDirective, [{
    type: Directive,
    args: [{
      selector: '[appFusionchartsDirective]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, null);
})();
