import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class AppHTTPrequestExtraProperties {

	private requestExtrasById = {};

	constructor() { }

	setExtraProperties = attrs => {

		const headers = {};

		if(attrs.extras){

			const id = (new Date()).getTime();

			this.requestExtrasById[id] = attrs.extras;

			headers['request-id-extras'] = id.toString();

		}

		return headers;

	};

	getRequestExtras = (req: HttpRequest<any>): object => {

		const id = req.headers.get('request-id-extras');

		if(id && this.requestExtrasById[id]){

			const extras = this.requestExtrasById[id];
			delete this.requestExtrasById[id];

			return extras;
		}

		return null;

	};



}
