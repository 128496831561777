import { AfterViewInit, Component, EventEmitter, OnDestroy, Output } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";

@Component({
	selector: 'sidenav-asset-ticket',
	template: ''
})
export class SidenavBaseComponent implements AfterViewInit, OnDestroy {

	sideNavRight: MatSidenav;

	@Output() sidenavBaseLoaded = new EventEmitter<boolean>();
	@Output() sidenavBaseDestroy = new EventEmitter<boolean>();

	ngAfterViewInit() {
		this.sidenavBaseLoaded.emit(true);
		this.sidenavBaseLoaded.complete();
	}

	ngOnDestroy() {
		this.sidenavBaseDestroy.emit(true);
	}

}
