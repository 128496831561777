import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { LoadingSpinnerDelegate } from 'src/app/components/loading-spinner/loading-spinner.interface';

@Component({
	selector: 'snack-bar-loading',
	templateUrl: './snack-bar-loading.component.html',
	styleUrls: ['./snack-bar-loading.component.scss']
})
export class SnackBarLoadingComponent {

	constructor(
		@Inject(MAT_SNACK_BAR_DATA) public snackbarData: SnackBarLoadingComponentData
	) {}

}

export interface SnackBarLoadingComponentData {
	delegate: LoadingSpinnerDelegate;
	message: string;
}
