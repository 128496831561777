import { Pipe, PipeTransform, Inject, LOCALE_ID, Injectable } from '@angular/core';
import { formatNumber } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
@Pipe({
	name: 'shortNumberSuffix'
})
export class ShortNumberSuffixPipe implements PipeTransform {

	constructor(
		@Inject(LOCALE_ID) private locale: string,
	){}

	transform(currentNumber: number, args?: any): any {

		let suffix = '';
		let value: string = currentNumber.toString();

		const powers = [
			{key: 'Q', value: Math.pow(10, 15)},
			{key: 'T', value: Math.pow(10, 12)},
			{key: 'B', value: Math.pow(10, 9)},
			{key: 'M', value: Math.pow(10, 6)},
			{key: 'K', value: 1000}
		];

		for(const power of powers){
			if(currentNumber >= power.value){
				suffix = power.key;
				value = formatNumber(currentNumber / power.value, this.locale, '1.0-1');
				break;
			}
		}

		return value + suffix;

	}
}
