import { environment as envDefault } from './environment.default';

export const environment = Object.assign(
	envDefault,
	{
		env: 'dev',
		production: false,
		proxyGet_enc_url: 'https://appdev.namoa.io/dev_get_enc_url/',
		portalWeb: 'https://dev.namoadigital.com',
		api: {
			host: '//apidev.namoa.io'
		}
	}
);
