import { Injectable } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class RoutesService {

	private routes = {};
	private activatedRoute = {
		path: ''
	};

	constructor(
		private router: Router
	){

		this.router.events.subscribe(event => {
			if(
				event instanceof ActivationEnd
				&&
				event.snapshot.routeConfig.data?.basePath
			){
				this.activatedRoute.path = event.snapshot.data?.basePath || '';
			}
		});

	}

	addRoute(route){
		Object.assign(
			this.routes,
			route
		);
	}

	getRoutes(){
		return this.routes;
	}

	getRoute(name){
		return this.routes[name] || {};
	}

	getActivatedRoute(){
		return this.activatedRoute;
	}

}
