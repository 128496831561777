import { Directive, Self, Output, EventEmitter } from '@angular/core';
import { MatOption } from '@angular/material/core';

@Directive({
	selector: 'mat-option[no-space-selection]'
})
export class MatOptionNoSpaceSelectionDirective {
	@Output('spacekeydown') spacekeydown: EventEmitter<any> =
		new EventEmitter<any>();

	constructor(@Self() private option: MatOption) {
		this.option._handleKeydown = (event: KeyboardEvent) => {
			if (event.code === 'Space') {
				// this.select.open();
				return;
			// } else {
			// 	if (!this.select.disabled) {
			// 		this.select.panelOpen
			// 			? (this.select as any)._handleOpenKeydown(event)
			// 			: (this.select as any)._handleClosedKeydown(event);
				// }
			}
			if (event.code === 'Enter') {
				option._selectViaInteraction();
			}
		};
	}
}
