import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiUrls } from 'src/api-urls/urls';
import { APIPagination } from 'src/app/helpers/api-pagination.interface';
import { AppHttpRequestService } from 'src/app/helpers/app-http-request';

@Injectable({
	providedIn: 'root'
})
export class APIPortalMasterDataClientsService {

	constructor(
		private appHttpRequestService: AppHttpRequestService,
	) { }

	get(
		data: APIPortalMasterDataClientsFilterModel,
		attrs: any
	): Observable<HttpResponse<APIPortalMasterDataClientsGETRoot>> {

		return this.appHttpRequestService.getRequest({
			method: 'get',
			url: ApiUrls.portal.masterDataClient,
			headers: Object.assign(
				attrs.headers || {},
				{
					filters: JSON.stringify(data)
				}
			),
			extras: attrs.extras || {},
			params: attrs.params || {}
		});
	}

	post(
		data: Omit<APIPortalMasterDataClients, 'clientCode'>,
		attrs: any
	): Observable<HttpResponse<Pick<APIPortalMasterDataClients, 'clientCode'>>> {

		return this.appHttpRequestService.getRequest({
			method: 'post',
			payload: data,
			url: ApiUrls.portal.masterDataClient,
			headers: Object.assign(
				attrs.headers || {},
				{
					// filters: JSON.stringify(data)
				}
			),
			extras: attrs.extras || {},
			params: attrs.params || {}
		});
	}

	put(
		clientCode: number,
		data: Omit<APIPortalMasterDataClients, 'clientCode'>,
		attrs: any
	): Observable<HttpResponse<Pick<APIPortalMasterDataClients, 'clientCode'>>> {

		return this.appHttpRequestService.getRequest({
			method: 'put',
			payload: data,
			url: `${ApiUrls.portal.masterDataClient}/${clientCode}`,
			headers: Object.assign(
				attrs.headers || {},
				{
					// filters: JSON.stringify(data)
				}
			),
			extras: attrs.extras || {},
			params: attrs.params || {}
		});
	}
}

export interface APIPortalMasterDataClientsFilterModel {
	active: number;
}

export interface APIPortalMasterDataClientsGETRoot {
	clients: APIPortalMasterDataClients[];
	links: APIPagination;
}

export interface APIPortalMasterDataClients {
	clientCode: number;
	clientId: string;
	clientName: string;
	clientEmail: string;
	clientPhone: string;
	active: 0 | 1;
	countryId: string;
	zipcode: string;
	street: string;
	district: string;
	state: string;
	city: string;
	streetnumber: string;
	longitude: number;
	latitude: number;
	plus_code: string;
	contact: string;
	phone: string;
	complement: string;
}
