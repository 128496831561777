import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})

export class AuthRolesService {

	private role: appRoles;

	constructor() {

	}

	setRole(role: appRoles){
		if(!this.role){
			this.role = role;
		}
	}

	getRole(): appRoles {
		return this.role;
	}

}

export enum appRoles {
	dashboard = 'dashboard',
	nformNonuser = 'nformNonuser'
}
