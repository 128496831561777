import { Subscription } from 'rxjs';

const getObservableAndAutoUnsubscribe = (
	obj: AutoUnsubscribeOnDestroy,
	subs: Subscription
) => {
	obj.obs.push(subs);
	return subs;
};

const autoUnsubscribe = (obj: AutoUnsubscribeOnDestroy) => {
	obj.obs.map((item: Subscription) => item.unsubscribe());
};

interface AutoUnsubscribeOnDestroy {
	obs: Subscription[];
}

export {
	AutoUnsubscribeOnDestroy,
	autoUnsubscribe,
	getObservableAndAutoUnsubscribe
};
