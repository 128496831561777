import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { appRoles } from './auth-roles.service';

@Injectable({
	providedIn: 'root'
})

export class AuthLogoutService {

	private _logout = new Subject();

	logout(navigate = false, postToAPI = false, appRole: appRoles = null){
		this._logout.next({
			navigate, postToAPI, appRole
		});
	}

	getSubscribe(): Subject<any>{
		return this._logout;
	}

}
