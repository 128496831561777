import { Component, Input, OnInit } from '@angular/core';
import { AppDeviceTypeService } from 'src/app/app.device-type.service';
import { AppService } from 'src/app/app.service';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'footer-app',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

	currentDevice;
	currentDate = new Date();
	customerObj = this.authService.getCustomerObj();
	appVersion = environment.version;
	whiteLabelProperties = this.appService.getWhiteLabelProperties();

	@Input() subdomainNameInline = false;
	@Input() versionInline = false;
	@Input() hideLogo = false;
	@Input() hideVersion = false;

	constructor(
		private appDeviceTypeService: AppDeviceTypeService,
		private appService: AppService,
		private authService: AuthService,
	) {
		this.setCurrentDevice();
	}

	ngOnInit(): void {
	}

	private setCurrentDevice = () =>
		this.currentDevice = this.appDeviceTypeService.device;

}
