<div
	layout="row"
	flex
>
	<div
		flex
		layout="row"
		layout-align="start center"
	>
		<span>{{snackbarData.message}}</span>
	</div>

	<div
		layout="row"
		layout-align="center center"
	>
		<loading-spinner
			[delegate]="snackbarData.delegate"
			size="36"
		></loading-spinner>
	</div>
</div>
