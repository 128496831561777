<div
	layout="column"
>
	<div>
		@if(whiteLabelProperties?.subdomain){

			@if(subdomainNameInline){
				{{whiteLabelProperties.name}} |
			}@else{
				<div>
					{{whiteLabelProperties.name}}
				</div>
			}

			Powered by

		}
		<a
			href="https://www.namoadigital.com"
			target="_blank"
			class="footer-link"
		>
			@if(hideLogo || whiteLabelProperties){
				Namoa Digital
			}@else{
				<img src="/assets/logo-bg-dark.svg" />
			}
		</a>
		@if (!hideVersion) {
			@if(versionInline){
				v{{appVersion}}
			}@else {
				<div layout="row" layout-align="end">v{{appVersion}}</div>
			}
		}
	</div>
</div>
