import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppHTTPrequestExtraProperties } from './app-http-request-extra-properties';

@Injectable({
	providedIn: 'root'
})

export class AppHttpRequestService {

	constructor(
		private http: HttpClient,
		private appHTTPrequestExtraProperties: AppHTTPrequestExtraProperties
	) {
	}

	private requestExtrasById = {};

	getRequest = (attrs): Observable<HttpResponse<any>> | Observable<any> => {

		// const httpHeaders = this.appHTTPrequestExtraProperties.setExtraProperties(attrs);

		const http = this.http.request<any>(
			attrs.method,
			attrs.url,
			{
				body: attrs.payload,
				observe: attrs.observe || 'response',
				headers: Object.assign(
					{},
					attrs.headers || {},
					{extras: JSON.stringify(attrs.extras || {})}, // workaround to extra request information such as friendly messages
					// httpHeaders
				),
				params: attrs.params,
				reportProgress: attrs.reportProgress || false
			}
		);

		return http;

	};

	getRequestExtras = (req: HttpRequest<any>): object =>
		this.appHTTPrequestExtraProperties.getRequestExtras(req);

}
