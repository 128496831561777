<div
	#boxdrop
	flex
	class="box-drop"
	layout="column"
	(dragenter)="dragFileStart($event)" (dragover)="dragFileStart($event)"
>
	<div
		layout="column"
		class="drop-placeholder"
	>
		<div
			class="drop-overlay-get-events"
			(dragend)="dragFileEnd($event)" (dragleave)="dragFileEnd($event)" (drop)="dragFileEnd($event)"
		></div>
		<div
			flex
			layout="column"
			layout-align="center center"
			class="drop-overlay"
		>
			<mat-icon svgIcon="download"></mat-icon>
			<span>{{translatePlaceHolder | transloco}}</span>
		</div>
	</div>
	<ng-content></ng-content>
</div>
