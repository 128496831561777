import { Injectable } from '@angular/core';
import { AppHttpRequestService } from 'src/app/helpers/app-http-request';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { ApiUrls } from 'src/api-urls/urls';

@Injectable({
	providedIn: 'root'
})
export class APIPortalLogoutService {

	constructor(
		private appHttpRequestService: AppHttpRequestService
	) { }

	get(
		attrs
	): Observable<HttpResponse<any>> {

		return this.appHttpRequestService.getRequest({
			method: 'get',
			url: ApiUrls.portal.authLogout,
			extras: attrs.extras || {}
		});

	}

}
