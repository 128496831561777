import { Injectable } from '@angular/core';
import { UserPermissions } from './auth.interface';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root'
})

export class AuthPermissionsService {

	emptyArrayOfPermissions: UserPermissions[] = [];

	constructor(
		private authService: AuthService
	){}

	has(key: UserPermissions): boolean{
		return (this.authService.getTokenInfo()?.permissions || this.emptyArrayOfPermissions).includes(key);
	}

}
