import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
	selector: 'suffix-prefix-wrapper',
	templateUrl: './suffix-prefix.component.html',
	styleUrls: ['./suffix-prefix.component.scss']
})
export class SuffixPrefixWrapperComponent extends FieldWrapper implements AfterViewInit {

	@ViewChild('matPrefix') matPrefix: TemplateRef<any>;
	@ViewChild('matSuffix') matSuffix: TemplateRef<any>;

	ngAfterViewInit() {

		if (this.matPrefix) {
			Promise.resolve().then(() => this.to.prefix = this.matPrefix);
		}

		if (this.matSuffix) {
			Promise.resolve().then(() => this.to.suffix = this.matSuffix);
		}

	}

	addonSuffixClick($event: any) {
		if (this.to.addonSuffix.onClick) {
			this.to.addonSuffix.onClick(this.to, this, $event);
		}
	}

	addonPrefixClick($event: any) {
		if (this.to.addonPrefix.onClick) {
			this.to.addonPrefix.onClick(this.to, this, $event);
		}
	}

}
