<div
	#dateTimePickerTrigger="matMenuTrigger"
	[matMenuTriggerFor]="dateTimePickerMenu"
>
</div>

<ng-template #timepicker>
	<div
		layout="row"
		layout-align="center center"
		class="time-picker"
	>
		<button
			mat-stroked-button
			[matMenuTriggerFor]="timeMenuHours"
			[disabled]="disabled"
		>{{timePickerValue | date: 'HH'}}</button>
		<span>:</span>
		<button
			mat-stroked-button
			[matMenuTriggerFor]="timeMenuMinutes1"
			[disabled]="disabled"
		>{{timePickerValue | date: 'mm'}}</button>

		<div
			*ngIf="timePickerValue && showResetTimePickerFormControlButton"
			class="reset-btn-container"
			layout="row"
			layout-align="center center"
		>
			<div (click)="resetTimePickerValue()" class="reset-background-btn">
				<mat-icon flex="none" svgIcon="close"></mat-icon>
			</div>
		</div>
	</div>

	<mat-menu #timeMenuHours="matMenu">
		<div
			layout="column"
			class="mat-menu-hours"
		>
			<menu
				*ngFor="let hour of list.listHours"
				mat-menu-item
				(click)="timePickerOnChange($event, hour, 'hour')"
			>
				<div layout="row" layout-align="start center">
					<span>{{hour}}</span>
					<mat-icon
						*ngIf="(timePickerValue | date: 'HH') === hour"
						class="time-checked"
						svgIcon="check"
					></mat-icon>
				</div>
			</menu>
		</div>
	</mat-menu>

	<mat-menu #timeMenuMinutes1="matMenu">
		<menu
			*ngFor="let minute1 of list.listMinutes1; let index=index"
			mat-menu-item
			(click)="timePickerOnChange($event, minute1, 'minute')"
			[matMenuTriggerFor]="timeMenuMinutes2"
			[matMenuTriggerData]="{
				minutes1: index
			}"
		>
			<div layout="row" layout-align="start center">
				<span>{{minute1}}</span>
				<mat-icon
					*ngIf="(timePickerValue | date: 'mm') === minute1"
					class="time-checked"
					svgIcon="check"
				></mat-icon>
			</div>
		</menu>
	</mat-menu>

	<mat-menu #timeMenuMinutes2="matMenu">
		<ng-template matMenuContent let-minutes1="minutes1">
			<menu
				*ngFor="let minute2 of list.listMinutes2"
				mat-menu-item
				(click)="timePickerOnChange($event, minutes1+''+minute2, 'minute')"
			>
				<div layout="row" layout-align="start center">
					<span>{{minutes1}}{{minute2}}</span>
					<mat-icon
						*ngIf="(timePickerValue | date: 'mm') === minutes1+''+minute2"
						class="time-checked"
						svgIcon="check"
					></mat-icon>
				</div>
			</menu>
		</ng-template>
	</mat-menu>
</ng-template>

<mat-menu #dateTimePickerMenu yPosition="above">
	<ng-template matMenuContent>
		<div
			layout="column"
			layout-margin
			no-margin
			class="datetime-picker-menu"
			(click)="preventCloseMenu($event)"
		>

			<mat-calendar
				(selectedChange)="datePickerOnChange($event)"
				[(selected)]="datePickerDefaultValue"
				[startAt]="datePickerDefaultValue"
				[minDate]="minCalendarDate"
				[maxDate]="maxCalendarDate"
			></mat-calendar>

			<div
				layout="column"
				layout-align="start center"
			>
				<!-- <ngx-mat-timepicker-field
					[format]="24"
					[defaultTime]="datePickerDefaultValue | date: 'HH:mm'"
					[controlOnly]="true"
					(timeChanged)="dateTimeOnChange($event, datePickerDefaultValue)"
					>
				</ngx-mat-timepicker-field> -->

				<ng-container
					*ngIf="!inlineTimepicker"
					[ngTemplateOutlet]="timepicker"
				></ng-container>

				<div
					class="error"
					*ngIf="!isDateTimeValid()"
				>
					{{'Route.ServiceOrderPlanning.Components.AssetTicket.KanbanDateError' | transloco}}
				</div>

				<div
					layout="row"
					layout-align="center"
					layout-margin
					class="buttons"
					[ngClass]="{
						'no-error': isDateTimeValid()
					}"
					>
					<button
						layout="row"
						layout-align="center center"
						flex="none"
						no-margin-top
						mat-stroked-button
						color="warn"
						(click)="datePickerCancel()"
					>
						{{'Components.NForm.HOUR.picker-btn-cancel' | transloco}}
					</button>
					<button
						layout="row"
						layout-align="center center"
						flex="none"
						no-margin-top
						mat-stroked-button
						color="primary"
						(click)="datePickerApply()"
						[disabled]="!isDateTimeValid()"
						>
						{{'Components.NForm.HOUR.picker-btn-confirm' | transloco}}
					</button>
				</div>
			</div>
		</div>
	</ng-template>
</mat-menu>

<ng-container
	*ngIf="inlineTimepicker"
	[ngTemplateOutlet]="timepicker"
></ng-container>
