import { HttpClient } from '@angular/common/http';
import {
	Translation,
	TranslocoLoader,
	TranslocoModule,
	TranslocoService,
	provideTransloco
} from '@jsverse/transloco';
import { Injectable, NgModule, APP_INITIALIZER } from '@angular/core';
import { environment } from 'src/environments/environment';
import { I18nService } from './i18n.service';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import { APIportalTranslatesService } from '../api-services/portal/translates.service';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {

	constructor(private http: HttpClient) {}

	getTranslation(lang: string) {
		console.log(TranslocoHttpLoader.name, 'getTranslation()', lang);
		return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
	}

}

function preloadLocale(
	i18nService: I18nService,
	translocoService: TranslocoService,
	aPIportalTranslatesService: APIportalTranslatesService
) {
	return () => {

		console.log('appinit', I18nModule.name, i18nService.detectedLocale);

		const activeLocale = translocoService.getActiveLang();

		return lastValueFrom(translocoService.load(activeLocale));

	};
}

@NgModule({
	exports: [
		TranslocoModule
	],
	providers: [
		provideTransloco({
			config: {
				availableLangs: ['en', 'pt', 'de'],
				defaultLang: 'en',
				fallbackLang: 'en',
				reRenderOnLangChange: true,
				prodMode: environment.production,
			},
			loader: TranslocoHttpLoader
		}),

		// { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },

		{
			provide: APP_INITIALIZER,
			multi: true,
			useFactory: preloadLocale,
			deps: [I18nService, TranslocoService, APIportalTranslatesService]
		},

		provideTranslocoMessageformat()

	]
})
export class I18nModule {}
