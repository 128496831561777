import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { NavigationBarService } from '../navigation-bar.service';

@Component({
	selector: 'navigation-bar-extras',
	templateUrl: './extras.component.html',
	styleUrls: ['./extras.component.scss']
})
export class NavigationBarExtrasComponent implements AfterViewInit {

	@ViewChild('wrapper', {read: ViewContainerRef}) viewContainerRef: ViewContainerRef;

	constructor(
		private navigationBarService: NavigationBarService
	) { }

	ngAfterViewInit(): void {
		this.navigationBarService.extrasRef = this.viewContainerRef;
	}

}
