import { TranslocoService } from '@jsverse/transloco';

export function formlyValidationConfig(
	translocoService: TranslocoService
){
	return {
		validationMessages: [
			{
				name: 'required',
				message(error, field){
					return translocoService
						.selectTranslate('Form.ValidationMessage.required');
				}
			},
			{
				name: 'minLength',
				message(error, field){
					return translocoService
						.selectTranslate(
							'Form.ValidationMessage.minLength',
							{
								value: field.templateOptions.minLength
							}
						);
				}
			},
			{
				name: 'maxLength',
				message(error, field){
					return translocoService
						.selectTranslate(
							'Form.ValidationMessage.maxLength',
							{
								value: field.templateOptions.maxLength
							}
						);
				}
			}
		],
	};
}
