import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { Subject } from 'rxjs';
import { formatDate } from '@angular/common';
import {
	pick as _pick,
	mergeWith as _mergeWith
} from 'lodash';
import { AuthService } from 'src/app/auth/auth.service';
import { DashboardFilterComponentName } from './components/filter-level/filter-level.component';
@Injectable({
	providedIn: 'root'
})
export class DashboardService {

	model: DashboardServiceFilter = {
		dateRangeStart: '',
		dateRangeEnd: '',
		[DashboardFilterLevels.management]: 1,
		[DashboardFilterLevels.operational]: 0,
		metric: FilterMetrics.generalScore,
		[FilterDimensions.tags]: [],
		[FilterDimensions.profitsCenter]: [],
		[FilterDimensions.profitsCenterGroupbyL1]: [],
		[FilterDimensions.profitsCenterGroupbyL2]: [],
		[FilterDimensions.profitsCenterGroupbyL3]: [],
		[FilterDimensions.profitsCenterGroupbyL4]: [],
		[FilterDimensions.sites]: [],
		[FilterDimensions.products]: [],
		[FilterDimensions.economicGroup]: null,
	};

	private dashboardFilterObserver: Subject<FilterObservableResult>;

	constructor(
		@Inject(LOCALE_ID) private locale: string,
		private authService: AuthService
	){

		this.initModel();
		this.dashboardFilterObserver = new Subject<FilterObservableResult>();

		this.authService.getLogoutObserver().subscribe(() => this.initModel());

	}

	private setDateRangeModel(){

		const currentDate = new Date();

		currentDate.setDate(currentDate.getDate() - 1);
		this.model.dateRangeEnd = formatDate(currentDate, 'yyyy-MM-dd', this.locale);

		currentDate.setDate(1);
		this.model.dateRangeStart = formatDate(currentDate, 'yyyy-MM-dd', this.locale);

	}

	initModel(){
		Object.assign(
			this.model,
			{
				dateRangeStart: '',
				dateRangeEnd: '',
				metric: FilterMetrics.generalScore
			}
		);
		this.setDateRangeModel();
		this.clearModelDimensions();
	}

	applyFilter(
		model?: any,
		origin?: CardDimensionTypes | FilterMetrics | DashboardFilterComponentName
	){

		if(model){

			_mergeWith(
				this.model,
				model,
				(objValue, srcValue) => {

					if(Array.isArray(objValue)){

						if(!Array.isArray(srcValue)){
							srcValue = [srcValue];
						}

						// convert string values to number
						srcValue.map((value, key) => {
							srcValue[key] = value === null || isNaN(value as number)
								? value
								: parseFloat(value as string);
						});

						objValue.splice(0);
						objValue.push.apply(objValue, srcValue);

						return objValue;

					}else{

						// convert string values to number
						return srcValue === null || isNaN(srcValue as number)
							? srcValue
							: parseFloat(srcValue as string);

					}

				}
			);

		}

		this.dashboardFilterObserver.next({
			origin,
			model: this.model
		});

	}

	getFilterObservable = () => this.dashboardFilterObserver;

	showBtnClearFilters = () => {

		let show = false;

		Object.entries(this.model).map(([key, value]) => {
			if(Object.values(FilterDimensions).includes(key as FilterDimensions)
				&&
				(
					!Array.isArray(this.model[key])
					&&
					!!this.model[key]
					||
					Array.isArray(this.model[key])
					&&
					(this.model[key] as Array<any>).length
				)
			){
				show = true;
			}
		});

		return show;

	};

	clearModelDimensions = () =>
		Object.entries(this.model).map(([key, value]) => {
			if(Object.values(FilterDimensions)
				.includes(key as FilterDimensions)
			){
				if(Array.isArray(this.model[key])){
					this.model[key].splice(0);
				}else{
					this.model[key] = null;
				}
			}
		});

	clearFilters(){
		this.clearModelDimensions();
		this.applyFilter();
	}

	pickFilterDimensions(
		model: DashboardServiceFilter,
		hideNullAndEmptyArrays: boolean = false
	){
		return _pick(
			model,
			Object.entries(FilterDimensions)
				.map(([key, value]) => value)
				.filter(key => {

					if(!hideNullAndEmptyArrays){
						return true;
					}

					if(Array.isArray(model[key])){
						return (model[key] as Array<any>).length;
					}else{
						return model[key] !== null;
					}

				})
		);
	}

	toggleModelItemSelected(
		filter: FilterDimensions,
		id: number | string
	){

		if(Array.isArray(this.model[filter])){

			const list = this.model[filter] as Array<any>;
			const indexOf = list.indexOf(id);

			// remove
			if(indexOf >= 0){
				list.splice(indexOf, 1);
			// add
			}else{
				list.push(id);
			}

		}

	}

}

export interface FilterObservableResult {
	 origin: CardDimensionTypes | FilterMetrics | DashboardFilterComponentName | null;
	 model: DashboardServiceFilter;
}

export interface DashboardServiceFilter {
	[DashboardFilterLevels.management]?: 0 | 1;
	[DashboardFilterLevels.operational]?: 0 | 1;
	[FilterDimensions.tags]: (string | number)[];
	[FilterDimensions.profitsCenter]: (string | number)[];
	[FilterDimensions.profitsCenterGroupbyL1]: (string | number)[];
	[FilterDimensions.profitsCenterGroupbyL2]: (string | number)[];
	[FilterDimensions.profitsCenterGroupbyL3]: (string | number)[];
	[FilterDimensions.profitsCenterGroupbyL4]: (string | number)[];
	[FilterDimensions.sites]: (string | number)[];
	[FilterDimensions.products]: (string | number)[];
	[FilterDimensions.economicGroup]: number;
	dateRangeStart: string;
	dateRangeEnd: string;
	metric: FilterMetrics;
}

export enum CardDimensionTypes {
	tags = 'tags',
	profitsCenter = 'profits-center',
	profitsCenterGroupbyL1 = 'profits-center-groupby-l1-user',
	profitsCenterGroupbyL2 = 'profits-center-groupby-l2-user',
	profitsCenterGroupbyL3 = 'profits-center-groupby-l3-user',
	profitsCenterGroupbyL4 = 'profits-center-groupby-l4-user',
	sites = 'sites',
	products = 'products',
	economicGroup = 'economic-group'
}

export type CardDimensionProperties = {
	[
	key in
	CardDimensionTypes.tags |
	CardDimensionTypes.profitsCenter |
	CardDimensionTypes.profitsCenterGroupbyL1 |
	CardDimensionTypes.profitsCenterGroupbyL2 |
	CardDimensionTypes.profitsCenterGroupbyL3 |
	// CardDimensionTypes.profitsCenterGroupbyL4 |
	CardDimensionTypes.sites |
	CardDimensionTypes.products |
	CardDimensionTypes.economicGroup
	]: CardDimensionProperty;
};

export interface CardDimensionProperty {
	filterDimension: FilterDimensions;
	id: string;
	descExtra1: string;
	desc: string;
	groupby?: string;
}

export enum FilterDimensions {
	tags = 'tags',
	profitsCenter = 'profits-center',
	profitsCenterGroupbyL1 = 'profits-center-groupby-l1-user',
	profitsCenterGroupbyL2 = 'profits-center-groupby-l2-user',
	profitsCenterGroupbyL3 = 'profits-center-groupby-l3-user',
	profitsCenterGroupbyL4 = 'profits-center-groupby-l4-user',
	sites = 'sites',
	products = 'products',
	economicGroup = 'economic-group'
}

export enum DashboardFilterLevels {
	management = 'P_MANAGEMENT',
	operational = 'P_OPERATIONAL',
}

export enum FilterMetrics {
	generalScore = 'general-score',
	plannedActivities = 'planned-activities',
	qualitativeEvaluation = 'qualitative-evaluation',
	executions = 'executions'
}

export enum MetricsValueProperties {
	'general-score' = 'WEIGHT',
	'planned-activities' = 'SCHEDULE',
	'qualitative-evaluation' = 'SCORE',
	'executions' = 'QTY',
	'evolution-general-score' = 'EVOLUTION_WEIGHT',
	'evolution-planned-activities' = 'EVOLUTION_SCHEDULE',
	'evolution-qualitative-evaluation' = 'EVOLUTION_SCORE',
	'evolution-executions' = 'EVOLUTION_QTY'
}
