import { animation, style, animate, keyframes } from '@angular/animations';

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes} checked by tsc
 */
const /** @type {?} */DEFAULT_TIMING = 1;

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes} checked by tsc
 */
const /** @type {?} */bounce = animation([style({
  transform: 'translate3d(0, 0, 0)'
}), animate('{{ timing }}s {{ delay }}s', keyframes([style({
  transform: 'translate3d(0, 0, 0)',
  offset: 0.2
}), style({
  transform: 'translate3d(0, -30px, 0)',
  offset: 0.4
}), style({
  transform: 'translate3d(0, 0, 0)',
  offset: 0.53
}), style({
  transform: 'translate3d(0, -15px, 0)',
  offset: 0.7
}), style({
  transform: 'translate3d(0, -4px, 0)',
  offset: 0.9
}), style({
  transform: 'translate3d(0, 0, 0)',
  offset: 1
})]))], {
  params: {
    timing: DEFAULT_TIMING,
    delay: 0
  }
});
const /** @type {?} */flash = animation(animate('{{ timing }}s {{ delay }}s', keyframes([style({
  opacity: 1
}), style({
  opacity: 0
}), style({
  opacity: 1
}), style({
  opacity: 0
}), style({
  opacity: 1
})])), {
  params: {
    timing: DEFAULT_TIMING,
    delay: 0
  }
});
const /** @type {?} */pulse = animation(animate('{{ timing }}s {{ delay }}s', keyframes([style({
  transform: 'scale3d(1, 1, 1)'
}), style({
  transform: 'scale3d({{ scale }}, {{ scale }}, {{ scale }})'
}), style({
  transform: 'scale3d(1, 1, 1)'
})])), {
  params: {
    scale: 1.25,
    timing: DEFAULT_TIMING,
    delay: 0
  }
});
const /** @type {?} */rubberBand = animation(animate('{{ timing }}s {{ delay }}s', keyframes([style({
  transform: 'scale3d(1, 1, 1)',
  offset: 0
}), style({
  transform: 'scale3d(1.25, 0.75, 1)',
  offset: 0.3
}), style({
  transform: 'scale3d(0.75, 1.25, 1)',
  offset: 0.4
}), style({
  transform: 'scale3d(1.15, 0.85, 1)',
  offset: 0.5
}), style({
  transform: 'scale3d(.95, 1.05, 1)',
  offset: 0.65
}), style({
  transform: 'scale3d(1.05, .95, 1)',
  offset: 0.75
}), style({
  transform: 'scale3d(1, 1, 1)',
  offset: 1
})])), {
  params: {
    timing: DEFAULT_TIMING,
    delay: 0
  }
});
const /** @type {?} */shake = animation(animate('{{ timing }}s {{ delay }}s', keyframes([style({
  transform: 'translate3d(0, 0, 0)',
  offset: 0
}), style({
  transform: 'translate3d(-10px, 0, 0)',
  offset: 0.1
}), style({
  transform: 'translate3d(10px, 0, 0)',
  offset: 0.2
}), style({
  transform: 'translate3d(-10px, 0, 0)',
  offset: 0.3
}), style({
  transform: 'translate3d(10px, 0, 0)',
  offset: 0.4
}), style({
  transform: 'translate3d(-10px, 0, 0)',
  offset: 0.5
}), style({
  transform: 'translate3d(10px, 0, 0)',
  offset: 0.6
}), style({
  transform: 'translate3d(-10px, 0, 0)',
  offset: 0.7
}), style({
  transform: 'translate3d(10px, 0, 0)',
  offset: 0.8
}), style({
  transform: 'translate3d(-10px, 0, 0)',
  offset: 0.9
}), style({
  transform: 'translate3d(0, 0, 0)',
  offset: 1
})])), {
  params: {
    timing: DEFAULT_TIMING,
    delay: 0
  }
});
const /** @type {?} */swing = animation(animate('{{ timing }}s {{ delay }}s', keyframes([style({
  transform: 'rotate3d(0, 0, 1, 15deg)',
  offset: 0.2
}), style({
  transform: 'rotate3d(0, 0, 1, -10deg)',
  offset: 0.4
}), style({
  transform: 'rotate3d(0, 0, 1, 5deg)',
  offset: 0.6
}), style({
  transform: 'rotate3d(0, 0, 1, -5deg)',
  offset: 0.8
}), style({
  transform: 'rotate3d(0, 0, 1, 0deg)',
  offset: 1
})])), {
  params: {
    timing: DEFAULT_TIMING,
    delay: 0
  }
});
const /** @type {?} */tada = animation(animate('{{ timing }}s {{ delay }}s', keyframes([style({
  transform: 'scale3d(1, 1, 1)',
  offset: 0
}), style({
  transform: 'scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg)',
  offset: 0.1
}), style({
  transform: 'scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg)',
  offset: 0.2
}), style({
  transform: 'scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)',
  offset: 0.3
}), style({
  transform: 'scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)',
  offset: 0.4
}), style({
  transform: 'scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)',
  offset: 0.5
}), style({
  transform: 'scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)',
  offset: 0.6
}), style({
  transform: 'scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)',
  offset: 0.7
}), style({
  transform: 'scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)',
  offset: 0.8
}), style({
  transform: 'scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)',
  offset: 0.9
}), style({
  transform: 'scale3d(1, 1, 1)',
  offset: 1
})])), {
  params: {
    timing: DEFAULT_TIMING,
    delay: 0
  }
});
const /** @type {?} */wobble = animation(animate('{{ timing }}s {{ delay }}s', keyframes([style({
  transform: 'none',
  offset: 0
}), style({
  transform: 'translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg)',
  offset: 0.15
}), style({
  transform: 'translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg)',
  offset: 0.3
}), style({
  transform: 'translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg)',
  offset: 0.45
}), style({
  transform: 'translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg)',
  offset: 0.6
}), style({
  transform: 'translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg)',
  offset: 0.75
}), style({
  transform: 'none',
  offset: 1
})])), {
  params: {
    timing: DEFAULT_TIMING,
    delay: 0
  }
});
const /** @type {?} */jello = animation(animate('{{ timing }}s {{ delay }}s', keyframes([style({
  transform: 'none',
  offset: 0
}), style({
  transform: 'none',
  offset: 0.11
}), style({
  transform: 'skewX(-12.5deg) skewY(-12.5deg)',
  offset: 0.22
}), style({
  transform: 'skewX(6.25deg) skewY(6.25deg)',
  offset: 0.33
}), style({
  transform: 'skewX(-3.125deg) skewY(-3.125deg)',
  offset: 0.44
}), style({
  transform: 'skewX(1.5625deg) skewY(1.5625deg)',
  offset: 0.55
}), style({
  transform: 'skewX(-0.78125deg) skewY(-0.78125deg)',
  offset: 0.66
}), style({
  transform: 'skewX(0.390625deg) skewY(0.390625deg)',
  offset: 0.77
}), style({
  transform: 'skewX(-0.1953125deg) skewY(-0.1953125deg)',
  offset: 0.88
}), style({
  transform: 'none',
  offset: 1
})])), {
  params: {
    timing: DEFAULT_TIMING,
    delay: 0
  }
});

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes} checked by tsc
 */
const /** @type {?} */bounceIn = animation(animate('{{ timing }}s {{ delay }}s cubic-bezier(0.215, 0.610, 0.355, 1.000)', keyframes([style({
  opacity: 0,
  transform: 'scale3d(.3, .3, .3)',
  offset: 0
}), style({
  transform: 'scale3d(1.1, 1.1, 1.1)',
  offset: 0.2
}), style({
  transform: 'scale3d(.9, .9, .9)',
  offset: 0.4
}), style({
  opacity: 1,
  transform: 'scale3d(1.03, 1.03, 1.03)',
  offset: 0.6
}), style({
  transform: 'scale3d(.97, .97, .97)',
  offset: 0.8
}), style({
  opacity: 1,
  transform: 'scale3d(1, 1, 1)',
  offset: 1
})])), {
  params: {
    timing: DEFAULT_TIMING,
    delay: 0
  }
});
/**
 * @param {?} a
 * @param {?} b
 * @param {?} c
 * @param {?} d
 * @return {?}
 */
function bounceInY(a, b, c, d) {
  return animation(animate('{{ timing }}s {{ delay }}s cubic-bezier(0.215, 0.610, 0.355, 1.000)', keyframes([style({
    opacity: 0,
    transform: 'translate3d(0, {{ a }}, 0)',
    offset: 0
  }), style({
    opacity: 1,
    transform: 'translate3d(0, {{ b }}, 0)',
    offset: 0.6
  }), style({
    transform: 'translate3d(0, {{ c }}, 0)',
    offset: 0.75
  }), style({
    transform: 'translate3d(0, {{ d }}, 0)',
    offset: 0.9
  }), style({
    opacity: 1,
    transform: 'none',
    offset: 1
  })])), {
    params: {
      timing: DEFAULT_TIMING,
      delay: 0,
      a,
      b,
      c,
      d
    }
  });
}
/**
 * @param {?} a
 * @param {?} b
 * @param {?} c
 * @param {?} d
 * @return {?}
 */
function bounceInX(a, b, c, d) {
  return animation(animate('{{ timing }}s {{ delay }}s cubic-bezier(0.215, 0.610, 0.355, 1.000)', keyframes([style({
    opacity: 0,
    transform: 'translate3d({{ a }}, 0, 0)',
    offset: 0
  }), style({
    opacity: 1,
    transform: 'translate3d({{ b }}, 0, 0)',
    offset: 0.6
  }), style({
    transform: 'translate3d({{ c }}, 0, 0)',
    offset: 0.75
  }), style({
    transform: 'translate3d({{ d }}, 0, 0)',
    offset: 0.9
  }), style({
    opacity: 1,
    transform: 'none',
    offset: 1
  })])), {
    params: {
      timing: DEFAULT_TIMING,
      delay: 0,
      a,
      b,
      c,
      d
    }
  });
}
const /** @type {?} */bounceInDown = bounceInY('-3000px', '25px', '-10px', '5px');
const /** @type {?} */bounceInUp = bounceInY('3000px', '-25px', '10px', '-5px');
const /** @type {?} */bounceInLeft = bounceInX('-3000px', '25px', '-10px', '5px');
const /** @type {?} */bounceInRight = bounceInX('3000px', '-25px', '10px', '-5px');
const /** @type {?} */bounceOut = animation(animate('{{ timing }}s {{ delay }}s', keyframes([style({
  transform: 'scale3d(.9, .9, .9)',
  offset: 0.2
}), style({
  opacity: 1,
  transform: 'scale3d({{ scale }}, {{ scale }}, {{ scale }})',
  offset: 0.5
}), style({
  opacity: 1,
  transform: 'scale3d({{ scale }}, {{ scale }}, {{ scale }})',
  offset: 0.55
}), style({
  opacity: 0,
  transform: 'scale3d(.3, .3, .3)',
  offset: 1
})])), {
  params: {
    timing: DEFAULT_TIMING,
    delay: 0,
    scale: 1.1
  }
});
/**
 * @param {?} a
 * @param {?} b
 * @param {?} c
 * @param {?} d
 * @return {?}
 */
function bounceOutY(a, b, c, d) {
  return animation(animate('{{ timing }}s {{ delay }}s', keyframes([style({
    transform: 'translate3d(0, {{ a }}, 0)',
    offset: 0.2
  }), style({
    opacity: 1,
    transform: 'translate3d(0, {{ b }}, 0)',
    offset: 0.4
  }), style({
    opacity: 1,
    transform: 'translate3d(0, {{ c }}, 0)',
    offset: 0.45
  }), style({
    opacity: 0,
    transform: 'translate3d(0, {{ d }}, 0)',
    offset: 1
  })])), {
    params: {
      timing: DEFAULT_TIMING,
      delay: 0,
      a,
      b,
      c,
      d
    }
  });
}
/**
 * @param {?} a
 * @param {?} b
 * @return {?}
 */
function bounceOutX(a, b) {
  return animation(animate('{{ timing }}s {{ delay }}s', keyframes([style({
    opacity: 1,
    transform: 'translate3d({{ a }}, 0, 0)',
    offset: 0.2
  }), style({
    opacity: 0,
    transform: 'translate3d({{ b }}, 0, 0)',
    offset: 1
  })])), {
    params: {
      timing: DEFAULT_TIMING,
      delay: 0,
      a,
      b
    }
  });
}
const /** @type {?} */bounceOutDown = bounceOutY('10px', '-20px', '-20px', '2000px');
const /** @type {?} */bounceOutUp = bounceOutY('-10px', '20px', '20px', '-2000px');
const /** @type {?} */bounceOutLeft = bounceOutX('20px', '-2000px');
const /** @type {?} */bounceOutRight = bounceOutX('-20px', '2000px');

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes} checked by tsc
 */
/**
 * @param {?} a
 * @param {?} b
 * @return {?}
 */
function fadeInX(a, b) {
  return animation(animate('{{ timing }}s {{ delay }}s', keyframes([style({
    opacity: 0,
    transform: 'translate3d({{ a }}, 0, 0)',
    offset: 0
  }), style({
    opacity: 1,
    transform: 'translate3d({{ b }}, 0, 0)',
    offset: 1
  })])), {
    params: {
      timing: DEFAULT_TIMING,
      delay: 0,
      a,
      b
    }
  });
}
/**
 * @param {?} a
 * @param {?} b
 * @return {?}
 */
function fadeInY(a, b) {
  return animation(animate('{{ timing }}s {{ delay }}s', keyframes([style({
    opacity: 0,
    transform: 'translate3d(0, {{ a }}, 0)',
    offset: 0
  }), style({
    opacity: 1,
    transform: 'translate3d(0, {{ b }}, 0)',
    offset: 1
  })])), {
    params: {
      timing: DEFAULT_TIMING,
      delay: 0,
      a,
      b
    }
  });
}
const /** @type {?} */fadeIn = fadeInX(0, 0);
const /** @type {?} */fadeInDown = fadeInY('-100%', 0);
const /** @type {?} */fadeInUp = fadeInY('100%', 0);
const /** @type {?} */fadeInLeft = fadeInX('-100%', 0);
const /** @type {?} */fadeInRight = fadeInX('100%', 0);
/**
 * @param {?} a
 * @param {?} b
 * @return {?}
 */
function fadeOutX(a, b) {
  return animation(animate('{{ timing }}s {{ delay }}s', keyframes([style({
    opacity: 1,
    transform: 'translate3d({{ a }}, 0, 0)',
    offset: 0
  }), style({
    opacity: 0,
    transform: 'translate3d({{ b }}, 0, 0)',
    offset: 1
  })])), {
    params: {
      timing: DEFAULT_TIMING,
      delay: 0,
      a,
      b
    }
  });
}
/**
 * @param {?} a
 * @param {?} b
 * @return {?}
 */
function fadeOutY(a, b) {
  return animation(animate('{{ timing }}s {{ delay }}s', keyframes([style({
    opacity: 1,
    transform: 'translate3d(0, {{ a }}, 0)',
    offset: 0
  }), style({
    opacity: 0,
    transform: 'translate3d(0, {{ b }}, 0)',
    offset: 1
  })])), {
    params: {
      timing: DEFAULT_TIMING,
      delay: 0,
      a,
      b
    }
  });
}
const /** @type {?} */fadeOut = fadeOutX(0, 0);
const /** @type {?} */fadeOutDown = fadeOutY('-100%', 0);
const /** @type {?} */fadeOutUp = fadeOutY('100%', 0);
const /** @type {?} */fadeOutLeft = fadeOutX('-100%', 0);
const /** @type {?} */fadeOutRight = fadeOutX('100%', 0);
/**
 * @param {?} a
 * @param {?} b
 * @return {?}
 */
function slideX(a, b) {
  return animation(animate('{{ timing }}s {{ delay }}s', keyframes([style({
    transform: 'translate3d({{ a }}, 0, 0)',
    offset: 0
  }), style({
    transform: 'translate3d({{ b }}, 0, 0)',
    offset: 1
  })])), {
    params: {
      timing: DEFAULT_TIMING,
      delay: 0,
      a,
      b
    }
  });
}
/**
 * @param {?} a
 * @param {?} b
 * @return {?}
 */
function slideY(a, b) {
  return animation(animate('{{ timing }}s {{ delay }}s', keyframes([style({
    transform: 'translate3d(0, {{ a }}, 0)',
    offset: 0
  }), style({
    transform: 'translate3d(0, {{ b }}, 0)',
    offset: 1
  })])), {
    params: {
      timing: DEFAULT_TIMING,
      delay: 0,
      a,
      b
    }
  });
}
const /** @type {?} */slideInUp = slideY('-100%', 0);
const /** @type {?} */slideInDown = slideY('100%', 0);
const /** @type {?} */slideInLeft = slideX('-100%', 0);
const /** @type {?} */slideInRight = slideX('100%', 0);
const /** @type {?} */slideOutUp = slideY(0, '-100%');
const /** @type {?} */slideOutDown = slideY(0, '100%');
const /** @type {?} */slideOutLeft = slideX(0, '-100%');
const /** @type {?} */slideOutRight = slideX(0, '100%');

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes} checked by tsc
 */
const /** @type {?} */flip = animation([style({
  'backface-visibility': 'visible'
}), animate('{{ timing }}s {{ delay }}s ease-out', keyframes([style({
  transform: 'perspective(400px) rotate3d(0, 1, 0, -360deg)',
  offset: 0
}), style({
  transform: 'perspective(400px) scale3d(1.5, 1.5, 1.5) rotate3d(0, 1, 0, -190deg)',
  offset: 0.4
}), style({
  transform: 'perspective(400px) scale3d(1.5, 1.5, 1.5) rotate3d(0, 1, 0, -170deg)',
  offset: 0.5
}), style({
  transform: 'perspective(400px) scale3d(.95, .95, .95)',
  offset: 0.8
}), style({
  transform: 'perspective(400px)',
  offset: 1
})]))], {
  params: {
    timing: DEFAULT_TIMING,
    delay: 0
  }
});
/**
 * @param {?} rotateX
 * @param {?} rotateY
 * @return {?}
 */
function flipIn(rotateX, rotateY) {
  return animation([style({
    'backface-visibility': 'visible'
  }), animate('{{ timing }}s {{ delay }}s ease-in', keyframes([style({
    opacity: 0,
    transform: 'perspective(400px) rotate3d({{ rotateX }}, {{ rotateY }}, 0, 90deg)',
    offset: 0
  }), style({
    opacity: 1,
    transform: 'perspective(400px) rotate3d({{ rotateX }}, {{ rotateY }}, 0, -20deg)',
    offset: 0.4
  }), style({
    transform: 'perspective(400px) rotate3d({{ rotateX }}, {{ rotateY }}, 0, 10deg)',
    offset: 0.6
  }), style({
    transform: 'perspective(400px) rotate3d({{ rotateX }}, {{ rotateY }}, 0, -5deg)',
    offset: 0.8
  }), style({
    transform: 'perspective(400px) rotate3d(0, 0, 0, 0)',
    offset: 1
  })]))], {
    params: {
      timing: DEFAULT_TIMING,
      delay: 0,
      rotateX,
      rotateY
    }
  });
}
const /** @type {?} */flipInX = flipIn(1, 0);
const /** @type {?} */flipInY = flipIn(0, 1);
/**
 * @param {?} rotateX
 * @param {?} rotateY
 * @return {?}
 */
function flipOut(rotateX, rotateY) {
  return animation([style({
    'backface-visibility': 'visible'
  }), animate('{{ timing }}s {{ delay }}s', keyframes([style({
    transform: 'perspective(400px)',
    offset: 0
  }), style({
    opacity: 1,
    transform: 'perspective(400px) rotate3d({{ rotateX }}, {{ rotateY }}, 0, -20deg)',
    offset: 0.3
  }), style({
    opacity: 0,
    transform: 'perspective(400px) rotate3d({{ rotateX }}, {{ rotateY }}, 0, 90deg)',
    offset: 1
  })]))], {
    params: {
      timing: DEFAULT_TIMING,
      delay: 0,
      rotateX,
      rotateY
    }
  });
}
const /** @type {?} */flipOutX = flipOut(1, 0);
const /** @type {?} */flipOutY = flipOut(0, 1);

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes} checked by tsc
 */
const /** @type {?} */lightSpeedIn = animation(animate('{{ timing }}s {{ delay }}s', keyframes([style({
  opacity: 0,
  transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
  offset: 0
}), style({
  opacity: 1,
  transform: 'translate3d(0, 0, 0) skewX(0)',
  offset: 1
})])), {
  params: {
    timing: DEFAULT_TIMING,
    delay: 0
  }
});
const /** @type {?} */lightSpeedOut = animation(animate('{{ timing }}s {{ delay }}s ease-out', keyframes([style({
  opacity: 1,
  offset: 0
}), style({
  opacity: 0,
  transform: 'translate3d(100%, 0, 0) skewX(30deg)',
  offset: 1
})])), {
  params: {
    timing: DEFAULT_TIMING,
    delay: 0
  }
});

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes} checked by tsc
 */
/**
 * @param {?} origin
 * @param {?} degrees
 * @return {?}
 */
function rotateInDirection(origin, degrees) {
  return animation(animate('{{ timing }}s {{ delay }}s', keyframes([style({
    'transform-origin': '{{ origin }}',
    opacity: 0,
    transform: 'rotate3d(0, 0, 1, {{ degrees }})',
    offset: 0
  }), style({
    'transform-origin': '{{ origin }}',
    opacity: 1,
    transform: 'none',
    offset: 1
  })])), {
    params: {
      timing: DEFAULT_TIMING,
      delay: 0,
      origin,
      degrees
    }
  });
}
const /** @type {?} */rotateIn = rotateInDirection('center', '-200deg');
const /** @type {?} */rotateInDownLeft = rotateInDirection('left bottom', '-45deg');
const /** @type {?} */rotateInDownRight = rotateInDirection('right bottom', '45deg');
const /** @type {?} */rotateInUpLeft = rotateInDirection('left bottom', '45deg');
const /** @type {?} */rotateInUpRight = rotateInDirection('right bottom', '-90deg');
/**
 * @param {?} origin
 * @param {?} degrees
 * @return {?}
 */
function rotateOutDirection(origin, degrees) {
  return animation(animate('{{ timing }}s {{ delay }}s', keyframes([style({
    'transform-origin': '{{ origin }}',
    opacity: 1,
    transform: 'none',
    offset: 0
  }), style({
    'transform-origin': '{{ origin }}',
    opacity: 0,
    transform: 'rotate3d(0, 0, 1, {{ degrees }})',
    offset: 1
  })])), {
    params: {
      timing: DEFAULT_TIMING,
      delay: 0,
      origin,
      degrees
    }
  });
}
const /** @type {?} */rotateOut = rotateOutDirection('center', '200deg');
const /** @type {?} */rotateOutDownLeft = rotateOutDirection('left bottom', '45deg');
const /** @type {?} */rotateOutDownRight = rotateOutDirection('right bottom', '-45deg');
const /** @type {?} */rotateOutUpLeft = rotateOutDirection('left bottom', '-45deg');
const /** @type {?} */rotateOutUpRight = rotateOutDirection('right bottom', '90deg');

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes} checked by tsc
 */
const /** @type {?} */hinge = animation([style({
  'transform-origin': 'top left'
}), animate('{{ timing }}s {{ delay }}s ease-in-out', keyframes([style({
  transform: 'rotate3d(0, 0, 1, 80deg)',
  offset: 0.2
}), style({
  transform: 'rotate3d(0, 0, 1, 60deg)',
  offset: 0.4
}), style({
  transform: 'rotate3d(0, 0, 1, 80deg)',
  offset: 0.6
}), style({
  opacity: 1,
  transform: 'rotate3d(0, 0, 1, 60deg)',
  offset: 0.8
}), style({
  opacity: 0,
  transform: 'translate3d(0, 700px, 0)',
  offset: 1
})]))], {
  params: {
    timing: DEFAULT_TIMING,
    delay: 0
  }
});
const /** @type {?} */jackInTheBox = animation([animate('{{ timing }}s {{ delay }}s', keyframes([style({
  opacity: 0,
  transform: 'scale(0.1) rotate(30deg)',
  'transform-origin': 'center bottom',
  offset: 0
}), style({
  opacity: 0.5,
  transform: 'rotate(-10deg)',
  offset: 0.5
}), style({
  opacity: 0.7,
  transform: 'rotate(3deg)',
  offset: 0.7
}), style({
  opacity: 1,
  transform: 'scale(1)',
  offset: 1
})]))], {
  params: {
    timing: DEFAULT_TIMING,
    delay: 0
  }
});
const /** @type {?} */rollIn = animation([animate('{{ timing }}s {{ delay }}s', keyframes([style({
  opacity: 0,
  transform: 'translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg)',
  offset: 0
}), style({
  opacity: 1,
  transform: 'none',
  offset: 1
})]))], {
  params: {
    timing: DEFAULT_TIMING,
    delay: 0
  }
});
const /** @type {?} */rollOut = animation([animate('{{ timing }}s {{ delay }}s', keyframes([style({
  opacity: 1,
  offset: 0
}), style({
  opacity: 0,
  transform: 'translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg)',
  offset: 1
})]))], {
  params: {
    timing: DEFAULT_TIMING,
    delay: 0
  }
});

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes} checked by tsc
 */
const /** @type {?} */zoomIn = animation([animate('{{ timing }}s {{ delay }}s', keyframes([style({
  opacity: 0,
  transform: 'scale3d(.3, .3, .3)',
  offset: 0
}), style({
  opacity: 1,
  transform: 'scale3d(1, 1, 1)',
  offset: 0.5
})]))], {
  params: {
    timing: DEFAULT_TIMING,
    delay: 0
  }
});
/**
 * @param {?} a
 * @param {?} b
 * @return {?}
 */
function zoomInY(a, b) {
  return animation(animate('{{ timing }}s {{ delay }}s cubic-bezier(0.550, 0.055, 0.675, 0.190)', keyframes([style({
    opacity: 0,
    transform: `scale3d(.1, .1, .1) translate3d(0, {{ a }}, 0)`,
    offset: 0
  }), style({
    opacity: 1,
    transform: `scale3d(.475, .475, .475) translate3d(0, {{ b }}, 0)`,
    offset: 0.6
  })])), {
    params: {
      timing: DEFAULT_TIMING,
      delay: 0,
      a,
      b
    }
  });
}
/**
 * @param {?} a
 * @param {?} b
 * @return {?}
 */
function zoomInX(a, b) {
  return animation(animate('{{ timing }}s {{ delay }}s cubic-bezier(0.550, 0.055, 0.675, 0.190)', keyframes([style({
    opacity: 0,
    transform: `scale3d(.1, .1, .1) translate3d({{ a }}, 0, 0)`,
    offset: 0
  }), style({
    opacity: 1,
    transform: `scale3d(.475, .475, .475) translate3d({{ b }}, 0, 0)`,
    offset: 0.6
  })])), {
    params: {
      timing: DEFAULT_TIMING,
      delay: 0,
      a,
      b
    }
  });
}
const /** @type {?} */zoomInDown = zoomInY('-1000px', '10px');
const /** @type {?} */zoomInUp = zoomInY('1000px', '-10px');
const /** @type {?} */zoomInLeft = zoomInX('-1000px', '10px');
const /** @type {?} */zoomInRight = zoomInX('1000px', '-10px');
const /** @type {?} */zoomOut = animation([animate('{{ timing }}s {{ delay }}s', keyframes([style({
  opacity: 1,
  offset: 0
}), style({
  opacity: 0,
  transform: 'scale3d(.3, .3, .3)',
  offset: 0.5
}), style({
  opacity: 0,
  offset: 1
})]))], {
  params: {
    timing: DEFAULT_TIMING,
    delay: 0
  }
});
/**
 * @param {?} a
 * @param {?} b
 * @return {?}
 */
function zoomOutY(a, b) {
  return animation(animate('{{ timing }}s {{ delay }}s cubic-bezier(0.550, 0.055, 0.675, 0.190)', keyframes([style({
    opacity: 1,
    transform: `scale3d(.475, .475, .475) translate3d(0, {{ a }}, 0)`,
    offset: 0.4
  }), style({
    opacity: 0,
    transform: `scale3d(.1, .1, .1) translate3d(0, {{ b }}, 0)`,
    offset: 1
  })])), {
    params: {
      timing: DEFAULT_TIMING,
      delay: 0,
      a,
      b
    }
  });
}
/**
 * @param {?} a
 * @param {?} b
 * @return {?}
 */
function zoomOutX(a, b) {
  return animation(animate('{{ timing }}s {{ delay }}s cubic-bezier(0.550, 0.055, 0.675, 0.190)', keyframes([style({
    opacity: 1,
    transform: `scale3d(.475, .475, .475) translate3d({{ a }}, 0, 0)`,
    offset: 0.4
  }), style({
    opacity: 0,
    transform: `scale3d(.1, .1, .1) translate3d({{ b }}, 0, 0)`,
    offset: 1
  })])), {
    params: {
      timing: DEFAULT_TIMING,
      delay: 0,
      a,
      b
    }
  });
}
const /** @type {?} */zoomOutDown = zoomOutY('-60px', '2000px');
const /** @type {?} */zoomOutUp = zoomOutY('60px', '-2000px');
const /** @type {?} */zoomOutLeft = zoomOutX('42px', '-2000px');
const /** @type {?} */zoomOutRight = zoomOutX('-42px', '2000px');

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes} checked by tsc
 */

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes} checked by tsc
 */

export { bounce, flash, pulse, rubberBand, shake, swing, tada, wobble, jello, bounceIn, bounceInY, bounceInX, bounceInDown, bounceInUp, bounceInLeft, bounceInRight, bounceOut, bounceOutY, bounceOutX, bounceOutDown, bounceOutUp, bounceOutLeft, bounceOutRight, fadeInX, fadeInY, fadeIn, fadeInDown, fadeInUp, fadeInLeft, fadeInRight, fadeOutX, fadeOutY, fadeOut, fadeOutDown, fadeOutUp, fadeOutLeft, fadeOutRight, slideX, slideY, slideInUp, slideInDown, slideInLeft, slideInRight, slideOutUp, slideOutDown, slideOutLeft, slideOutRight, flip, flipIn, flipInX, flipInY, flipOut, flipOutX, flipOutY, lightSpeedIn, lightSpeedOut, rotateInDirection, rotateIn, rotateInDownLeft, rotateInDownRight, rotateInUpLeft, rotateInUpRight, rotateOutDirection, rotateOut, rotateOutDownLeft, rotateOutDownRight, rotateOutUpLeft, rotateOutUpRight, hinge, jackInTheBox, rollIn, rollOut, zoomIn, zoomInY, zoomInX, zoomInDown, zoomInUp, zoomInLeft, zoomInRight, zoomOut, zoomOutY, zoomOutX, zoomOutDown, zoomOutUp, zoomOutLeft, zoomOutRight, DEFAULT_TIMING as ɵa };

