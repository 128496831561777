import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { combineLatest, Observable } from 'rxjs';
import { APIportalTranslatesService } from '../api-services/portal/translates.service';

@Injectable({
	providedIn: 'root'
})
export class TranslateLoadService {

	constructor(
		private translocoService: TranslocoService,
		private apiPortalTranslatesService: APIportalTranslatesService
	) {
	}

	// eslint-disable-next-line max-lines-per-function
	load(): Promise<any>{

		const data = {};

		// eslint-disable-next-line max-lines-per-function
		return new Promise(resolve => {

			const pageLimit = 500;

			const loadSubs = this.apiPortalTranslatesService.get({
				params: {
					limit: pageLimit,
					page: 1
				},
			}).subscribe({
				next: result => {

					const allPages: Observable<any>[] = [
						new Observable(subscriber => {
							subscriber.next({
								body: result.body
							});
						})
					];

					for(let i=2; i<=result.body.links.totalPages; i++){
						allPages.push(
							this.apiPortalTranslatesService.get({
								params: {
									limit: pageLimit,
									page: i
								}
							})
						);
					}

					const allPagesSubs = combineLatest(allPages)
						.subscribe({
							next: allPageResult => {
								allPageResult.map(item => {
									Object.assign(data, item.body.translates);
								});
								console.log('TranslateLoadService', data);
								this.translocoService.setTranslation(data);
								resolve(true);
								setTimeout(() => allPagesSubs.unsubscribe());
							}
						});

					loadSubs.unsubscribe();

				},
				error: () => resolve(true)
			});

		});
	}

}
