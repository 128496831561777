<span
	*ngIf="isPriceValue && authService.customer.currency_prefix && value !== null"
	matTextPrefix
	class="currency-symbol prefix"
>{{authService.customer.currency_prefix}}</span>

<span>{{value | number: decimalPlacesEqualizer: activeLang}}</span>

<span
	*ngIf="isPriceValue && authService.customer.currency_suffix && value !== null"
	matTextSuffix
	class="currency-symbol suffix"
>{{authService.customer.currency_suffix}}</span>
