<div class="btn-close" mat-dialog-close>
	<mat-icon svgIcon="close"></mat-icon>
</div>
<mat-dialog-content
	class="photo"
>
	<div
		flex
		style="
			background-image: url('{{data.url}}');
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
		"
	>
	</div>
</mat-dialog-content>
