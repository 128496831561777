import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppHttpRequestService } from 'src/app/helpers/app-http-request';
import { ApiUrls } from 'src/api-urls/urls';

@Injectable({
	providedIn: 'root'
})
export class APIPortalUtilsAddressZipcodeService {

	constructor(
		private appHttpRequestService: AppHttpRequestService
	) { }

	get(
		data: APIPortalUtilsAddressZipcodeParameters,
		attrs: any,
	): Observable<HttpResponse<APIPortalUtilsAddressZipcodeGETRoot>> {

		return this.appHttpRequestService.getRequest({
			method: 'get',
			url: ApiUrls.portal.utilsAddressZipcode,
			headers: attrs.headers || {},
			extras: attrs.extras || {},
			params: data
		});

	}

};

export interface APIPortalUtilsAddressZipcodeParameters {
	zipcode: string;
	countryCode: string;
}

export interface APIPortalUtilsAddressZipcodeGETRoot {
	zipcode: string;
	street: string;
	district: string;
	city: string;
	state: string;
}
