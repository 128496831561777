import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppHttpRequestService } from 'src/app/helpers/app-http-request';
import { ApiUrls } from 'src/api-urls/urls';

@Injectable({
	providedIn: 'root'
})
export class APIPortalUtilsAddressLatLongService {

	constructor(
		private appHttpRequestService: AppHttpRequestService
	) { }

	get(
		data: APIPortalUtilsAddressLatLongParameters,
		attrs: any,
	): Observable<HttpResponse<APIPortalUtilsAddressLatLongGETRoot>> {

		return this.appHttpRequestService.getRequest({
			method: 'get',
			url: ApiUrls.portal.utilsAddressLatLong,
			headers: attrs.headers || {},
			extras: attrs.extras || {},
			params: data
		});

	}

};

export interface APIPortalUtilsAddressLatLongParameters {
	zipcode: string;
	countryCode: string;
	street: string;
	// eslint-disable-next-line id-blacklist
	number: string;
	district: string;
	city: string;
	state: string;
}

export interface APIPortalUtilsAddressLatLongGETRoot {
	lat: number;
	lng: number;
	plus_code: string;
	district: string;
}
