import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { RoutesService } from '../routes.service';
import { DashboardService, DashboardServiceFilter } from '../dashboard-management/dashboard-management.service';

@Injectable({
	providedIn: 'root'
})
export class MetricsChartService {

	model: MetricsChartModel = {
		timeUnit: MetricsChartTimeUnit.month
	};

	private filterObserver = new Subject<MetricsChartFilterObservableResult>();

	constructor(
		private router: Router,
		private routesService: RoutesService,
		private dashboardService: DashboardService
	) { }

	setTimeUnit(timeUnit: MetricsChartTimeUnit){

		this.model.timeUnit = timeUnit;

		this.filterObserver.next({
			model: this.model
		});

	}

	getFilterObservable(): Subject<MetricsChartFilterObservableResult>{
		return this.filterObserver;
	}

	goToChart(metricParam, timeUnit, filters: DashboardServiceFilter) {

		this.router.navigate(
			[
				this.routesService.getRoute('metricsChart').path,
				metricParam,
				timeUnit
			],
			{
				queryParams: this.dashboardService.pickFilterDimensions(filters, true)
			}
		);

	}

}

export interface MetricsChartFilterObservableResult {
	 model: MetricsChartModel;
}

export interface MetricsChartModel {
	 timeUnit: MetricsChartTimeUnit;
}

export enum MetricsChartTimeUnit {
	day = 'day',
	month = 'month'
}
