import { APIPortalTicketExecuteServiceGETStepCtrlFormScoreStatus, APIPortalTicketExecuteServiceGETTicket } from "./ticket-execute.interface";

export enum NFormObjects {
	TAB = 'TAB',
	CHAR = 'CHAR',
	NUMBER = 'NUMBER',
	PHOTO = 'PHOTO',
	CHECKBOX = 'CHECKBOX',
	RATINGIMAGE = 'RATINGIMAGE',
	RATINGBAR = 'RATINGBAR',
	LABEL = 'LABEL',
	DATE = 'DATE',
	HOUR = 'HOUR',
	COMBOBOX = 'COMBOBOX',
	PICTURE = 'PICTURE'
}

export enum NFormAPIDAtaTicketCreateValues {
	OPTIONAL = 'OPTIONAL',
	REQUIRED = 'REQUIRED',
	HIDE = 'HIDE'
}

export interface NFormAPIRoot {
	data: NFormAPIData;
	translates: any;
}

export interface APIPortalNFormPostResponse {
	status: 'OK' | 'ERROR';
	ticket?: {
		ticketId: string;
	},
	pk: string;
}

export interface NFormAPIData {
	add_inf1: string;
	barcode_code: number;
	brand_desc: string;
	class_code?: number;
	class_color?: string;
	class_id?: string;
	customer_code: number;
	form: NFormAPIJson;
	form_data?: NFormAPIJsonForm;
	last_form_date: string;
	last_form_desc: string;
	model_desc: string;
	operation_code: number;
	operation_desc: string;
	operation_id: string;
	product_code: number;
	product_desc: string;
	product_id: string;
	serial_code: number;
	serial_id: string;
	show_customer_icon: number;
	show_operation_icon: number;
	show_site_icon: number;
	show_system_icon: number;
	site_code: number;
	site_desc: string;
	site_id: string;
	ticket_create?: NFormAPIDataTicketCreate;
	ticket?: NFormAPIDataTicket[]
	zone_code?: number;
	zone_desc?: string;
	zone_id?: string;
}

export interface NFormAPIJson {
	customer_code: number;
	custom_form_type: number;
	custom_form_code: number;
	custom_form_version: number;
	require_signature: number;
	external_id: any;
	external_version: any;
	automatic_fill: number;
	require_location: number;
	require_serial_done: number;
	tab: NFormAPIJsonTab[];
	translate: NFormAPIJsonTranslate[];
}

export interface NFormAPIJsonForm {
	custom_form_status: NFormAPIJsonFormStatus;
	custom_form_status_txt: string;
	data_fields?: NFormAPIJsonFormDataFields[];
	date_start: string;
	date_end: string;
	score_perc: string;
	score_status: APIPortalTicketExecuteServiceGETStepCtrlFormScoreStatus;
	tag_operational_desc: string;
	user_nick_start: string;
	user_nick_end: string;
	customer_code: number;
	custom_form_type: number;
	custom_form_code: number;
	custom_form_version: number;
	custom_form_data: number;
	custom_form_data_pk: string;
}

export enum NFormAPIJsonFormStatus {
	PENDING = 'PENDING',
	DONE = 'DONE',
	PROGRESS = 'PROGRESS'
}

export interface NFormAPIJsonFormDataFields {
	custom_form_seq: number;
	data_comment: string;
	data_nc: boolean;
	data_value: string;
	photo_waiting: boolean;
	data_photo1_waiting: boolean;
	data_photo2_waiting: boolean;
	data_photo3_waiting: boolean;
	data_photo4_waiting: boolean;
	data_photo1_url: string;
	data_photo2_url: string;
	data_photo3_url: string;
	data_photo4_url: string;
}

export interface NFormAPIJsonTab {
	custom_form_seq: number;
	custom_form_order: number;
	field: NFormAPIJsonField[];
}

export interface NFormAPIJsonField {
	custom_form_seq: number;
	custom_form_data_type: NFormObjects;
	custom_form_data_size?: number;
	custom_form_order: number;
	required: 0 | 1;
	automatic: any;
	require_photo_on_nc: number;
	custom_form_data_mask?: NFormAPIJsonCustomFormDataMask;
	custom_form_data_content?: NFormAPIJsonCustomFormDataContent;
	nc: any;
}

export interface NFormAPIJsonCustomFormDataMask {
	type: string;
	value: string;
}

export interface NFormAPIJsonCustomFormDataContent {
	[prop: string]: any;
	edit?: string;
	gallery?: '0' | '1';
	high_resolution?: string;
	decimal?: number;
	value?: string;
	div?: string;
	min?: string;
	max?: string;
	color?: string;
	columns?: string;
	image?: string;
	lines?: string;
	combobox_options?: NFormAPIJsonCustomFormDataContentComboboxOptions;
}

export type NFormAPIJsonCustomFormDataContentComboboxOptions = {
	value: 'string';
}[];

export interface NFormAPIJsonTranslate {
	translate_code: number;
	language_code: string;
	translate_desc: string;
}

export interface NFormAPIDataTicket {
	kanban: 0 | 1;
	ticket_id: string;
	type_desc: string;
	create_date: string;
	internal_comments: string;
	kanban_form: string;
	kanban_date: string;
	type_nc_desc: any;
	open_photo: string;
	open_comments: string;
}

export interface NFormAPIDataTicketCreate {
	open_comments: NFormAPIDAtaTicketCreateValues;
	open_email: NFormAPIDAtaTicketCreateValues;
	open_name: NFormAPIDAtaTicketCreateValues;
	open_phone: NFormAPIDAtaTicketCreateValues;
	open_photo: NFormAPIDAtaTicketCreateValues;
	open_serial_stopped: NFormAPIDAtaTicketCreateValues;
	open_desired_date: NFormAPIDAtaTicketCreateValues;
}

export type NFormAPIPayloadTicketCreate = {
	[prop in keyof Omit<
	NFormAPIDataTicketCreate,
	'open_serial_stopped'
	| 'open_desired_date'
	>]?: string | number;
} & Partial<Pick<
APIPortalTicketExecuteServiceGETTicket,
'desired_date'
| 'is_serial_stopped'
| 'stopped_date'
>>
