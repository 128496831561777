import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms';
import { FormControlsOf } from 'src/app/helpers/form-controls';
import { FormControlAddressComponentInputs } from '../../form-fields/address/address.component';
import { LoadingSpinnerDelegate } from '../../loading-spinner/loading-spinner.interface';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { OwnerType } from 'src/app/api-services/portal/service-order-planning.interface';
import { APIPortalMasterDataClients, APIPortalMasterDataClientsService } from 'src/app/api-services/portal/master-data/clients.service';
import { TranslocoService } from '@jsverse/transloco';
import { SidenavBaseComponent } from 'src/app/helpers/sidenav-base.component';

@Component({
	selector: 'master-data-clients',
	templateUrl: './clients.component.html',
	styleUrls: ['./clients.component.scss']
})
export class MasterDataClientsComponent
	extends SidenavBaseComponent implements OnInit {

	error: any;
	loading = true;
	mode: 'add' | 'edit' = 'add';
	ownerType = OwnerType;
	loadingFormSubmit = false;

	editClientCode: number;

	formGroup = this.formBuilder.group<FormControlsOf<Omit<APIPortalMasterDataClients, 'clientCode'>>>({
		clientId: this.formBuilder.control(''),
		clientEmail: this.formBuilder.control('', [Validators.email]),
		clientName: this.formBuilder.control('', [Validators.required]),
		clientPhone: this.formBuilder.control(''),

		active: this.formBuilder.control(1),

		countryId: this.formBuilder.control('', [Validators.required]),
		zipcode: this.formBuilder.control(null),
		street: this.formBuilder.control('', [Validators.required]),
		district: this.formBuilder.control(''),
		state: this.formBuilder.control('', [Validators.required]),
		city: this.formBuilder.control('', [Validators.required]),
		streetnumber: this.formBuilder.control('', [Validators.required]),
		longitude: this.formBuilder.control(null),
		latitude: this.formBuilder.control(null),
		plus_code: this.formBuilder.control(''),
		complement: this.formBuilder.control(''),
		contact: this.formBuilder.control(''),
		phone: this.formBuilder.control(''),
	});

	formFieldAddressInputFormsControl: FormControlAddressComponentInputs = {
		countryId: this.formGroup.get('countryId'),
		zipcode: this.formGroup.get('zipcode') as FormControl<any>,
		street: this.formGroup.get('street') as FormControl<any>,
		streetnumber: this.formGroup.get('streetnumber') as FormControl<any>,
		complement: this.formGroup.get('complement') as FormControl<any>,
		district: this.formGroup.get('district') as FormControl<any>,
		state: this.formGroup.get('state') as FormControl<any>,
		city: this.formGroup.get('city') as FormControl<any>,
		contact: this.formGroup.get('contact') as FormControl<any>,
		phone: this.formGroup.get('phone') as FormControl<any>,
		latitude: this.formGroup.get('latitude') as FormControl<any>,
		longitude: this.formGroup.get('longitude') as FormControl<any>,
		plus_code: this.formGroup.get('plus_code') as FormControl<any>,
	}

	hasAddress = true;

	loadingSpinnerDelegate: LoadingSpinnerDelegate = {
		done: (status) => {
			this.loadingFormSubmit = false;
			this.formGroup.enable({emitEvent: false});

			this.hasAddressChanged({
				checked: this.hasAddress,
				source: null
			});
		},
		setStatus: (status: string) => ({})
	};

	@Input() masterDataClient: APIPortalMasterDataClients = null;

	@Output() newMasterDataClient = new EventEmitter<APIPortalMasterDataClients>;
	@Output() editMasterDataClient = new EventEmitter<APIPortalMasterDataClients>;
	@Output()	onCancelMasterDataClientSidenav = new EventEmitter<boolean>;

	constructor(
		private formBuilder: FormBuilder,
		private apiPortalMasterDataClientsService: APIPortalMasterDataClientsService,
		private translocoService: TranslocoService,
	) {
		super();
		this.setMode();
	}

	ngOnInit(): void {
	}

	setMode() {
		if(!this.masterDataClient) {
			this.mode = 'add';
		}
		else {
			this.mode = 'edit';
			this.formGroup.patchValue(this.masterDataClient);
			this.editClientCode = this.masterDataClient.clientCode;
			if(this.masterDataClient.street){
				this.hasAddress = true;
				this.hasAddressChanged({checked: true, source: null});
			}else{
				this.hasAddress = false;
				this.hasAddressChanged({checked: false, source: null});
			}
		}

		this.loading = false;
	}

	hasAddressChanged($event: MatSlideToggleChange){
		if($event.checked){
			this.formGroup.get('countryId').enable();
			this.formGroup.get('street').enable({emitEvent: false});
			this.formGroup.get('city').enable({emitEvent: false});
			this.formGroup.get('state').enable({emitEvent: false});
			this.formGroup.get('streetnumber').enable({emitEvent: false});
		}else{
			this.formGroup.get('countryId').disable();
			this.formGroup.get('street').disable({emitEvent: false});
			this.formGroup.get('city').disable({emitEvent: false});
			this.formGroup.get('state').disable({emitEvent: false});
			this.formGroup.get('streetnumber').disable({emitEvent: false});
		}
	}

	onCancelClientSidenav() {
		this.onCancelMasterDataClientSidenav.emit(true);
	}

	submit() {
		this.formGroup.markAllAsTouched();

		if(this.formGroup.invalid) {
			const arrayFormControlErrors = Object.values(this.formGroup.controls).map(
				control => control.errors
			);

			console.log('if invalid', arrayFormControlErrors);
			return;
		}

		this.clearAddressWhenDisabled();

		if(!this.formGroup.controls.clientEmail.value) {
			this.formGroup.controls.clientEmail.setValue(null);
		}

		this.loadingFormSubmit = true;
		this.formGroup.disable({emitEvent: false});

		if(this.mode === 'add'){
			this.submitAdd();
		}
		else if(this.mode === 'edit'){
			this.submitEdit();
		}
	}

	private clearAddressWhenDisabled(){
		if(!this.hasAddress){
			this.formGroup.patchValue({
				countryId: null,
				zipcode: null,
				street: null,
				district: null,
				state: null,
				city: null,
				streetnumber: null,
				latitude: null,
				longitude: null,
				plus_code: null
			}, {emitEvent: false});
		}
	}

	submitAdd() {
		this.apiPortalMasterDataClientsService.post(
			this.formGroup.getRawValue(),
			{
				extras: {
					friendlyMessage: {
						success: {
							message: this.translocoService.translate(
								'DefaultHttpRequestMessages.AddSuccessLabel',
								{
									label: this.translocoService.
										translate('Components.MasterData.Client.Label')
								}
							)
						}
					}
				}
			}
		).subscribe({
			next: result => {
				this.loadingSpinnerDelegate.setStatus('ok');
				const newClient: APIPortalMasterDataClients =
					this.formGroup.getRawValue() as APIPortalMasterDataClients;
				newClient.clientCode = result.body.clientCode;
				this.newMasterDataClient.emit(newClient);
			},
			error: () => this.loadingSpinnerDelegate.setStatus('error')
		});
	}

	submitEdit() {
		this.apiPortalMasterDataClientsService.put(
			this.editClientCode,
			this.formGroup.getRawValue(),
			{
				friendlyMessage: {
					success: {
						message: this.translocoService.translate(
							'DefaultHttpRequestMessages.UpdateSuccessLabel',
							{
								label: this.translocoService.
									translate('Components.MasterData.Client.Label')
							}
						)
					}
				}
			}
		).subscribe({
			next: () => {
				this.loadingSpinnerDelegate.setStatus('ok');
				const clientUpdated: APIPortalMasterDataClients =
					this.formGroup.getRawValue() as APIPortalMasterDataClients;
				clientUpdated.clientCode = this.editClientCode;
				this.editMasterDataClient.emit(clientUpdated);
			},
			error: () => this.loadingSpinnerDelegate.setStatus('error')
		});
	}

	setDisabledValue(
		$event: MatSlideToggleChange,
		formControl: AbstractControl,
		values: [any, any]
	){
		if(formControl){
			formControl.setValue($event.checked ? values[0] : values[1] );
		}
	}
}
