import { Injectable, Type, ViewContainerRef } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class NavigationBarService {

	extrasRef: ViewContainerRef;

	constructor() { }

	addExtra(component: Type<any>){
		setTimeout(() => {
			this.extrasRef.clear();
			this.extrasRef.createComponent(component);
		});
	}

	clearExtra(){
		this.extrasRef.clear();
	}

}
