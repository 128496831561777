<ng-template *ngIf="to.addonPrefix" #matPrefix>
	<span
		*ngIf="to.addonPrefix"
		class="formly-material-extension-prefix prefix"
		(click)="addonPrefixClick($event)"
	>
		<mat-icon *ngIf="to.addonPrefix.icon" svgIcon="{{to.addonPrefix.icon}}" matPrefix></mat-icon>
		<span *ngIf="to.addonPrefix.text" matPrefix>{{ to.addonPrefix.text }}</span>
	</span>
</ng-template>

<ng-container *ngIf="to.addonSuffix" #fieldComponent></ng-container>

<ng-template #matSuffix>
	<span
		*ngIf="to.addonSuffix"
		class="formly-material-extension-suffix suffix"
		(click)="addonSuffixClick($event)"
	>
		<mat-icon *ngIf="to.addonSuffix.icon" svgIcon="{{to.addonSuffix.icon}}" matSuffix></mat-icon>
		<span *ngIf="to.addonSuffix.text" matSuffix>{{ to.addonSuffix.text }}</span>
	</span>
</ng-template>
