import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { AppHttpRequestService } from 'src/app/helpers/app-http-request';

import { ApiUrls } from 'src/api-urls/urls';
import { APIPagination } from 'src/app/helpers/api-pagination.interface';

@Injectable({
	providedIn: 'root'
})
export class APIportalTranslatesService {

	constructor(
		private appHttpRequestService: AppHttpRequestService
	) {}

	get(
		attrs
	): Observable<HttpResponse<any>> {

		return this.appHttpRequestService.getRequest({
			method: 'get',
			url: ApiUrls.portal.translates,
			headers: attrs.headers || {},
			extras: attrs.extras || {},
			params: attrs.params || {}
		});

	}

}

export interface APIportalTranslatesGETRoot {
	translates: {
		[prop: string]: string;
	};
	links?: APIPagination;
}
