import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Md5 } from 'ts-md5/dist/md5';
import { HttpResponse } from '@angular/common/http';
import { ApiUrls } from 'src/api-urls/urls';
import { AppHttpRequestService } from 'src/app/helpers/app-http-request';
import { AuthTokenAPIGetResponse } from './auth-token.interface';
import { DatePipeNormalize } from 'src/app/pipes/date-parse-string';

@Injectable({
	providedIn: 'root'
})
export class APIPortalAuthTokenService {

	constructor(
		private appHttpRequestService: AppHttpRequestService,
		private datePipeNormalize: DatePipeNormalize
	) { }

	get(
		attrs
	): Observable<HttpResponse<AuthTokenAPIGetResponse>> {

		return this.appHttpRequestService.getRequest({
			method: 'get',
			url: ApiUrls.portal.authToken,
			extras: attrs.extras || {}
		});

	}

	post(
		data: APIPortalAuthTokenServicePostPayload,
		attrs
	): Observable<HttpResponse<any>> {

		console.log(this.datePipeNormalize.transform(new Date(), 'yyyy-MM-dd HH:mm:ss ZZZZZ'));

		data.P_PASSWORD = Md5.hashStr(data.P_PASSWORD).toString().toUpperCase();
		data.P_TIMEZONE = this.datePipeNormalize.transform(new Date(), 'ZZZZZ');

		return this.appHttpRequestService.getRequest({
			method: 'post',
			url: ApiUrls.portal.authToken,
			payload: data,
			extras: attrs.extras || {}
		});

	}

}

export interface APIPortalAuthTokenServicePostPayload {
	P_CUSTOMER_CODE: string;
	P_TRANSLATE_CODE: string | number;
	P_USER_CODE: string | number;
	P_PASSWORD: string;
	P_TIMEZONE?: string;
}

// export interface IRequestOptions {
// 	method: string;
// 	headers?: HttpHeaders;
// 	observe?: 'body';
// 	params?: HttpParams;
// 	reportProgress?: boolean;
// 	responseType?: 'json';
// 	withCredentials?: boolean;
// 	body?: any;
// }
