import { CdkCellDef } from '@angular/cdk/table';
import { Directive, Input } from '@angular/core';
import { MatCellDef, MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';

@Directive({
	selector: '[matCellDef]', // same selector as MatCellDef
	providers: [{ provide: CdkCellDef, useExisting: MatCellDefTypesafeDirective }],
})
export class MatCellDefTypesafeDirective<T> extends MatCellDef {
	@Input() matCellDefDataSource: T[] | Observable<T[]> | MatTableDataSource<T>;

	// eslint-disable-next-line @typescript-eslint/no-shadow
	static ngTemplateContextGuard<T>(
		dir: MatCellDefTypesafeDirective<T>,
		ctx: any,
	): ctx is { $implicit: T; index: number } {
		return true;
	}
}
