export interface LoadingSpinnerDelegate {
	done: (status: setDoneDelegate) => void;
	setStatus?: (status: setStatusDelegate) => void;
}

export enum setDoneDelegate {
	done = 'done',
	close = 'close'
}

export type setStatusDelegate = 'ok' | 'error';

