import { Injectable, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { environment } from 'src/environments/environment';
import { I18nModule } from './i18n/i18n.module';

import { Components } from './components/components.module';
import { FormlyModule, FORMLY_CONFIG } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { formlyValidationConfig } from './components/formly/formly-validation-config';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { TranslocoService } from '@jsverse/transloco';
import { MaterialModules } from './material.module';

import { FusionChartsModule } from 'angular-fusioncharts';
import FusionCharts from 'fusioncharts';
import charts from 'fusioncharts/fusioncharts.charts';
import TimeSeries from 'fusioncharts/fusioncharts.timeseries';
import Gantt from 'fusioncharts/fusioncharts.gantt';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import { SuffixPrefixWrapperComponent } from './components/formly/wrappers/suffix-prefix/suffix-prefix.component';
import { addonsExtension } from './components/formly/addons';

import { NgxMaskDirective, NgxMaskPipe, IConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { WebcamModule } from 'ngx-webcam';
import { MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/paginator';
import { GoogleMapsModule } from '@angular/google-maps';
import { DateAdapter, NativeDateAdapter } from '@angular/material/core';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@Injectable()
class ServiceOrderDateAdapter extends NativeDateAdapter {
	getFirstDayOfWeek(): number {
		return 1;
	}
}

// @ts-expect-error
FusionCharts.options.license({
	key: environment.fusionChartsKey,
	creditLabel: false
});

FusionChartsModule.fcRoot(FusionCharts, charts, TimeSeries, Gantt, FusionTheme);

@NgModule({
	declarations: [
		Components
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		FormlyModule.forRoot({
			wrappers: [
				{ name: 'suffix-prefix-wrapper', component: SuffixPrefixWrapperComponent },
			],
			extensions: [
				{ name: 'suffix-prefix-wrapperx', extension: { onPopulate: addonsExtension } },
			],
		}),
		FormlyMaterialModule,
		I18nModule,
		MaterialModules,
		GoogleMapsModule,
		FusionChartsModule,
		NgxMaskDirective,
		NgxMaskPipe,
		WebcamModule
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		FormlyModule,
		FormlyMaterialModule,
		I18nModule,
		MaterialModules,
		GoogleMapsModule,
		FusionChartsModule,
		NgxMaskDirective,
		NgxMaskPipe,
		WebcamModule,

		Components
	],
	providers: [
		DatePipe,
		{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
		{ provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: { formFieldAppearance: 'fill' } },
		{ provide: FORMLY_CONFIG, multi: true, useFactory: formlyValidationConfig, deps: [TranslocoService] },
		{
			provide: DateAdapter,
			useClass: ServiceOrderDateAdapter,
		},
		provideEnvironmentNgxMask(),
	]
})
export class SharedModule {
	constructor(
		private dateAdapter: DateAdapter<any>,
		private translocoService: TranslocoService
	){
		this.translocoService.langChanges$.subscribe(
			locale => this.dateAdapter.setLocale(locale)
		);
	}

}
