import { AuthTokenAPIGetResponse } from '../api-services/portal/auth-token.interface';
import { APIPortalAuthServicePOSTres } from '../api-services/portal/auth.interface';

export enum UserPermissions {
	routeDashboardManagement = 'WEBAPP_VIEW_DASH_MANAGEMENT',
	routeDashboardOperational = 'WEBAPP_VIEW_DASH_OPERATIONAL',
	routeAssets = 'WEBAPP_VIEW_SERIAL',
	routeNewTicket = 'WEBAPP_VIEW_SERIAL_EDIT',
	routeServiceOrderPlanning = 'WEBAPP_VIEW_SERIAL_PLANNING',
	routeFieldService = 'WEBAPP_VIEW_SERIAL_PLANNING',
	routeTechUserPlanning = 'WEBAPP_VIEW_TECHNICIAN_PLANNING',

	routeServiceOrderPlanningAdminSite = 'API_MAINTENANCE_PLAN.EDIT_SITE',
	routeServiceOrderPlanningAdminProduct = 'API_MAINTENANCE_PLAN.EDIT_PRODUCT',
	routeServiceOrderPlanningAdminAsset = 'API_MAINTENANCE_PLAN.EDIT_SERIAL',
	routeServiceOrderPlanningAdminUser = 'API_MAINTENANCE_PLAN.EDIT_USER',
	routeServiceOrderPlanningSerialBlock = 'API_MAINTENANCE_PLAN.SERIAL_BLOCK',
	routeServiceOrderPlanningAssetTicketEdit = 'API_TICKET.EDIT',

	routeServiceOrder = 'API_SERVICE',
	routeServiceOrderEdit = 'API_SERVICE.EDIT',
	routeServiceOrderEditDone = 'API_SERVICE.EDIT_DONE',
	routeServiceOrderExecution = 'API_SERVICE.EXECUTION',
	routeServiceOrderForceExecution = 'API_SERVICE.FORCE_EXECUTION',

};

export interface AuthServiceLoginData {
	authAPIPostResponse: APIPortalAuthServicePOSTres;
	authTokenAPIGetResponse: AuthTokenAPIGetResponse;
}
