const replaceMask = mask =>
	mask
		.replace(/[^ydmHMSZ]/g, '.')
		.replace(/y+/,'(?<y>\\d{4})')
		.replace(/([^\\])d+/,'$1(?<d>\\d{2})')
		.replace(/m+/,'(?<m>\\d{2})')
		.replace(/H+/,'(?<H>\\d{2})')
		.replace(/M+/,'(?<M>\\d{2})')
		.replace(/S+/,'(?<S>\\d{2})')
		.replace(/Z+/,'(?<Z>.*?$)');

const defaultMasks = {
	oracle1: replaceMask('yyyy-mm-dd HH:MM:SS Z'),
	oracle2: replaceMask('yyyy-mm-dd HH:MM:SS'),
	oracle3: replaceMask('yyyy-mm-dd HH:MM')
};

export function dateParseStringMaskTransform(dateString: string, mask?: string): string {


	if(!dateString){
		return null;
	}

	const matchRegexp = defaultMasks[mask] || new RegExp(replaceMask(mask));

	const match = dateString.match(matchRegexp);

	if(!match){
		return null;
	}

	return match.groups.y
		+ `-${match.groups.m}`
		+ `-${match.groups.d}`
		+ `T${match.groups.H}`
		+ `:${match.groups.M}`
		+ `:${match.groups.S || '00'}.000`
		+ (match.groups.Z || '').replace('UTC', 'Z');

}
