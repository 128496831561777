import { FormlyFieldConfig } from '@ngx-formly/core';

export function addonsExtension(field: FormlyFieldConfig) {

	if (!field.templateOptions || (field.wrappers && field.wrappers.indexOf('suffix-prefix-wrapper') !== -1)) {
		return;
	}

	if (field.templateOptions.addonSuffix || field.templateOptions.addonPrefix) {
		field.wrappers = [...(field.wrappers || []), 'suffix-prefix-wrapper'];
	}

}
