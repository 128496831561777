<mat-form-field
	*ngIf="showDesiredDateFormControl"
	flex="none"
	class="field-desired-date"
	(click)="dateTimePickerDesiredDate.dateTimePickerTrigger.openMenu()"
	[attr.mat-input-chip]="matChipStyle ? '' : null"
	[attr.no-margin-left]="matChipStyle ? '' : null"
	[attr.no-margin-right]="matChipStyle ? '' : null"
	wrapper-no-padding-bottom
>
	<mat-label>{{translateResourceName + ".OpenTicketInfo.InputDesiredDateLabel" | transloco}}</mat-label>

	<datetime-picker
		#dateTimePickerDesiredDate
		[datePickerDefaultValue]="desiredDateFormControl.value | dateParseString: 'oracle1' | date: 'yyyy-MM-ddTHH:mm:ssZZZZZ'"
		[formControlConnectValidators]="desiredDateFormControl.validator"
		(datePickerDefaultValueChange)="setFormControlDateTypeValue($event, desiredDateFormControl)"
		maskPlaceHolder="dd/MM/yyyy HH:mm"
		[minCalendarCurrentDate]="true"
	>
	</datetime-picker>

	<input type="text" matInput readonly [formControl]="dateTimePickerDesiredDate.formControlPlaceHolder">

	<mat-icon
		*ngIf="!desiredDateFormControl.value"
		svgIcon="event"
		matSuffix
	></mat-icon>

	<mat-icon
		*ngIf="desiredDateFormControl.value"
		(click)="
			$event.stopPropagation();
			dateTimePickerDesiredDate.reset();
		"
		svgIcon="ticket-status-canceled"
		matSuffix
		class="button-reset"
	></mat-icon>

</mat-form-field>

<div
	*ngIf="showIsSerialStoppedFormControl"
	[attr.layout]="isSerialStoppedLayout"
	[attr.layout-align]="isSerialStoppedLayout === 'row' ? 'start center' : 'start start' "
	class="field-serial-stopped"
>
	<mat-slide-toggle
		color="primary"
		flex="none"
		[checked]="isSerialStoppedFormControl.value === 1"
		(change)="
			isSerialStoppedFormControl.setValue(
				$event.checked
				? 1
				: 0
			)
		"
	>
		{{translateResourceName + ".OpenTicketInfo.InputSerialStoppedLabel" | transloco}}
	</mat-slide-toggle>

	<mat-form-field
		*ngIf="stoppedDateFormControl.enabled"
		(click)="dateTimePickerSerialStoppedDate.dateTimePickerTrigger.openMenu()"
		flex
		[attr.mat-input-chip]="matChipStyle ? '' : null"
		[attr.no-margin-left]="matChipStyle ? '' : null"
		[attr.no-margin-right]="matChipStyle ? '' : null"
		wrapper-no-padding-bottom
		wrapper-no-margin
	>
		<mat-label>{{translateResourceName + ".OpenTicketInfo.InputSerialStoppedDateLabel" | transloco}}</mat-label>

		<datetime-picker
			#dateTimePickerSerialStoppedDate
			[datePickerDefaultValue]="stoppedDateFormControl.value | dateParseString: 'oracle1' | date: 'yyyy-MM-ddTHH:mm:ssZZZZZ'"
			[formControlConnectValidators]="stoppedDateFormControl.validator"
			(datePickerDefaultValueChange)="setFormControlDateTypeValue($event, stoppedDateFormControl)"
			maskPlaceHolder="dd/MM/yyyy HH:mm"
			[maxCalendarCurrentDate]="true"
		>
		</datetime-picker>

		<input type="text" matInput readonly [formControl]="dateTimePickerSerialStoppedDate.formControlPlaceHolder">

		<mat-icon
			svgIcon="event"
			matSuffix
		></mat-icon>

	</mat-form-field>
</div>
