import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppHttpRequestService } from 'src/app/helpers/app-http-request';
import { ApiUrls } from 'src/api-urls/urls';

@Injectable({
	providedIn: 'root'
})
export class APIPortalUtilsAddressCountriesService {

	constructor(
		private appHttpRequestService: AppHttpRequestService
	) { }

	get(
		attrs: any
	): Observable<HttpResponse<APIPortalUtilsAddressCountriesGETRoot>> {

		return this.appHttpRequestService.getRequest({
			method: 'get',
			url: ApiUrls.portal.utilsAddressCountries,
			headers: attrs.headers || {},
			extras: attrs.extras || {}
		});

	}

};

export interface APIPortalUtilsAddressCountriesGETRoot {
	data: APIPortalUtilsAddressCountriesGETItem[];
}

export interface APIPortalUtilsAddressCountriesGETItem {
	COUNTRY_CODE: string;
	COUNTRY_DESC: string;
	COUNTRY_CUSTOMER: 0 | 1;
}
