<div
	layout="column"
	layout-align="center"
	class="sidenav-toolbar-serial-id"
>
	<div
		layout="row"
		layout-align="start center"
	>
		<span
			*ngIf="serial.classColor"
			flex="none"
			class="badge"
			[ngStyle]="{
				'--badge-color': serial.classColor
			}"
		></span>
		<mat-icon
			*ngIf="serialHasTicketOpenInfoOutOfOService"
			svgIcon="road-stop-signal"
			class="icon-serial-out-of-service"
		></mat-icon>
		<span
			class="serial-id"
			[ngClass]="{
				small,
				'font-auto-size': mobileSerialIdFontAutoSize
			}"
			[ngStyle]="{
				'--serial-id-font-size': mobileSerialIdFontSize + 'px'
			}"
		>{{serial.serialId}}</span>
	</div>
	<div class="product-info">
		<span class="product-info-product">{{serial.productDesc}}</span>
		<span class="product-info-brand">{{serial.brandDesc}}</span>
		<span class="product-info-model">{{serial.modelDesc}}</span>
		<span class="product-info-site">{{serial.siteDesc}}</span>
	</div>
</div>
