import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';

export enum WindowMessageActions {
	reload,
	serviceOrderPlanning_UpdateAsset,
	openTicket,
	openFormData
};

@Injectable({
	providedIn: 'root'
})
export class MainControllerWindowMessageListenerService {

	public readonly windowMessageReload =
		new Subject<WindowMessageData_Reload>();

	public readonly windowMessageServiceOrderPlanning_UpdateAsset =
		new Subject<WindowMessageData_ServiceOrderPlanning_UpdateAsset>();

	public readonly windowMessageOpenTicket =
		new Subject<WindowMessageData_OpenTicket>();

	public readonly windowMessageOpenFormData =
		new Subject<WindowMessageData_OpenFormData>();

	private observables = {
		[WindowMessageActions.reload]:
			this.windowMessageReload as Subject<WindowMessageData>,

		[WindowMessageActions.serviceOrderPlanning_UpdateAsset]:
			this.windowMessageServiceOrderPlanning_UpdateAsset as Subject<WindowMessageData>,

		[WindowMessageActions.openTicket]:
			this.windowMessageOpenTicket as Subject<WindowMessageData>,

		[WindowMessageActions.openFormData]:
			this.windowMessageOpenFormData as Subject<WindowMessageData>
	};

	constructor(private appService: AppService){

		self.addEventListener('message', (event) => {

			console.log('self.addEventListener', event);

			if (environment.production && !/namoadigital.com/.test(event.origin)){
				return;
			}

			const message = event.data as WindowMessageData;

			if(WindowMessageActions[message.action] !== undefined){
				console.log('MainControllerWindowMessageListenerService', message);
				this.observables[WindowMessageActions[message.action]].next(message);
			}

		}, false);

	}

	windowParentPostMessage(message: any){

		let origin;

		if(this.appService.getWhiteLabelProperties()?.subdomain){
			origin = '*';
		}else{
			origin = /apphomolog|appdev/.test(location.href) ? '*' : environment.portalWeb;
		}


		window.parent.postMessage(
			message,
			origin
		);

	}

}

export interface WindowMessageData {
	action: WindowMessageActions;
	data: any;
}

export interface WindowMessageData_Reload extends WindowMessageData {
	action: WindowMessageActions.reload;
	data: null;
}

export interface WindowMessageData_ServiceOrderPlanning_UpdateAsset extends WindowMessageData {
	action: WindowMessageActions.serviceOrderPlanning_UpdateAsset;
	data: {
		productCode: number;
		serialCode: number;
	};
}

export interface WindowMessageData_OpenTicket extends WindowMessageData {
	action: WindowMessageActions.openTicket;
	data: {
		ticketId: number;
	};
}

export interface WindowMessageData_OpenFormData extends WindowMessageData {
	action: WindowMessageActions.openFormData;
	data: {
		barcodeEnc: string;
	};
}

export enum IframePortalPostMessageActions {
	serialPopup = 'serviceOrderPlanningSerialPopup',
	ticketDetails = 'serviceOrderPlanningTicketDetailsPopup',
	cancellation = 'webapp_interfaceCancellation',
	onFormSave = 'webapp_onFormSave',
	onTicketSave = 'webapp_onTicketSave'
}

export interface IframePortalPostMessage {
	action: IframePortalPostMessageActions,
	data: any
}
